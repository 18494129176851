import Api from '../../../api';
import store from '../../../store';


const fetchMaintenanceInfoOverPeriod = async data => {
    // todo, moet juiste voor komen
    return fetchInfo('/Reporting/MaintenanceReport', data);
};

const fetchMaintenance = async data => {
    return fetchInfo('/Maintenance/GetMaintenance', data);
};

const addMaintenance = async data => {
    return await Api(store).post('/Maintenance/CreateMaintenance', data);
 };

 const deleteMaintenance = async data => {
    // return await Api(store).delete('/Maintenance/DeleteMaintenance', data);
    return Api(store).delete(
        '/Maintenance/DeleteMaintenance?' +
            Object.keys(data)
                .map(
                    k =>
                        encodeURIComponent(k) +
                        '=' +
                        encodeURIComponent(data[k] ?? '')
                )
                .join('&'),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    );
 };

 //#region Enums
const fetchMaintenanceActivities = async (data) => {    
    return fetchInfo('/Maintenance/GetMaintenanceActivities', data);
};

const fetchMaintenaceTypes = async (data) => {    
    return fetchInfo('/Maintenance/GetMaintenaceTypes', data);
};

//#endregion Categories


const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +'?' +
                Object.keys(data)
                    .map(
                        k =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};


export default {
    fetchMaintenanceInfoOverPeriod,
    fetchMaintenance,
    deleteMaintenance,
    addMaintenance,
    fetchMaintenanceActivities,
    fetchMaintenaceTypes   
};
