var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-input-group',{staticClass:"mb-2",attrs:{"prepend":_vm.showVanOp}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[(_vm.showDag)?_c('b-form-checkbox',{staticClass:"mr-n2",attrs:{"id":"checkbox-dag","name":"checkbox-dag","value":"day","unchecked-value":"period"},on:{"input":_vm.emitValues},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('com_dag', { ns: 'common' })))])]):_vm._e()],1),_c('b-form-datepicker',{attrs:{"id":"dateVan","date-format-options":{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit'
                }},on:{"input":_vm.emitValues},model:{value:(_vm.van),callback:function ($$v) {_vm.van=$$v},expression:"van"}})],1)],1),(!_vm.isDagSelectie)?_c('b-col',{attrs:{"sm":"6"}},[_c('b-input-group',{staticClass:"mb-2",attrs:{"prepend":_vm.$t('com_tot', { ns: 'common' }),"label":_vm.$t('com_tot', { ns: 'common' })}},[_c('b-form-datepicker',{attrs:{"id":"dateTot","date-format-options":{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit'
                }},on:{"input":_vm.emitValues},model:{value:(_vm.tot),callback:function ($$v) {_vm.tot=$$v},expression:"tot"}})],1)],1):_c('b-col',{attrs:{"sm":"6"}},[(_vm.showBakgroepOption)?_c('div',[_c('b-input-group',{staticClass:"mb-2 form-inline",attrs:{"label":_vm.strBakgroepPartij}},[_c('b-form-input',{attrs:{"id":"bg","placeholder":_vm.strBakgroepPartij},model:{value:(_vm.bgnummer),callback:function ($$v) {_vm.bgnummer=$$v},expression:"bgnummer"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"bgc_aux_normal",on:{"click":function($event){return _vm.getDateForBatchGroup()}}},[_vm._v("Ok")])],1)],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }