import Helper from './Helper';
import DateHelper from './DateHelper';
export {
    ProdRun,
    Bak,
    BakInfo,
    BakInfoHist,
    BatchStats,
    CheeseMarks,
    BakIngredient,
    BakDefaults,
    AlternativeCheese
};

class ProdRun {
    constructor(data) {
        this.id = -1;
        this.type = 'Bak';
        this.updated = null;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id ?? -1,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.updated,
                writable: false,
                enumerable: true
            },
            productieDatum: {
                value: DateHelper.convertedToDate(data.bookdate),
                writable: false,
                enumerable: true
            },
            status: {
                type: Number,
                value: data.runStatus,
                writable: false,
                enumerable: true
            },
            lijn: {
                type: Number,
                value: data.productionLineID,
                writable: false,
                enumerable: true
            },
            zuurselTypeNaam: {
                type: String,
                value: data.starterSubtypeProduct,
                writable: true,
                enumerable: true
            },
            zuurselTypeID: {
                type: Number,
                value: data.starterSubtypeProductID,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
}

class Bak {
    constructor(data) {
        this.id = -1;
        this.type = 'Bak';
        this.updated = null;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id ?? -1,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.updated,
                writable: false,
                enumerable: true
            },
            productieID: {
                value: data.prodRunID,
                writable: false,
                enumerable: true
            },
            bakno: {
                value: data.number,
                writable: true,
                enumerable: true
            },
            bakgroepID: {
                value: data.batchGroupID,
                writable: true,
                enumerable: true
            },
            bakgroepInternID: {
                value: data.batchgroup,
                writable: true,
                enumerable: true
            },
            receptID: {
                value: data.recipeID,
                writable: true,
                enumerable: true
            },
            opmerking: {
                value: data.remark,
                writable: true,
                enumerable: true
            },
            wbId: {
                value: data.ostId,
                writable: true,
                enumerable: true
            },
            wbProgram: {
                value: data.wbProgram,
                writable: true,
                enumerable: true
            },
            drainProgram: {
                value: data.drProgram,
                writable: true,
                enumerable: true
            },
            pressProgram: {
                value: data.pressProgram,
                writable: true,
                enumerable: true
            },
            bakType: {
                value: data.batchType,
                writable: true,
                enumerable: true
            },
            vetEiwit: {
                value: data.fatProteinRatio,
                writable: true,
                enumerable: true
            },
            stremTijdstip: {
                value: data.stremtijdstip,
                writable: true,
                enumerable: true
            },
            stremTemp: {
                value: data.stremTemp,
                writable: true,
                enumerable: true
            },
            pastTemp: {
                value: data.pastTemp,
                writable: true,
                enumerable: true
            },
            thermTemp: {
                value: data.thermTemp,
                writable: true,
                enumerable: true
            },
            nawarmTemp: {
                value: data.nawarmTemp,
                writable: true,
                enumerable: true
            },
            tussenTemp: {
                value: data.tussenTemp,
                writable: true,
                enumerable: true
            },
            melkKilos: {
                value: data.milkWeight,
                writable: true,
                enumerable: true
            },
            wei1Kilos: {
                value: data.firstWheyWeight,
                writable: true,
                enumerable: true
            },
            wei2Kilos: {
                value: data.secondWheyWeight,
                writable: true,
                enumerable: true
            },
            wei1liters: {
                value: data.firstWheyLiters,
                writable: true,
                enumerable: true
            },
            wasWater: {
                value: data.waswater,
                writable: true,
                enumerable: true
            },
            wei2aliters: {
                value: data.wheyLiters2a,
                writable: true,
                enumerable: true
            },
            wasWater2a: {
                value: data.waswater2a,
                writable: true,
                enumerable: true
            },
            geblokkeerd: {
                value: data.blocked,
                writable: true,
                enumerable: true
            },
            status: {
                value: data.runstatus,
                writable: true,
                enumerable: true
            },
            calcFactor: {
                value: data.productionCalcFactor ?? 1,
                writable: true,
                enumerable: true
            },
            overpompTijdstip: {
                value: data.overpompTijdstip,
                writable: true,
                enumerable: true
            },
            bakIngredienten: {
                value: [],
                writable: true,
                enumerable: true
            },
            recIngredienten: {
                value: [],
                writable: true,
                enumerable: true
            },
            bakStatistiek: {
                value: data.batchStats
                    ? data.batchStats.map((x) => new BatchStats(x))
                    : [],
                writable: true,
                enumerable: true
            },
            kaasMerken: {
                value: data.cheeseMarks
                    ? new CheeseMarks(data.cheeseMarks)
                    : null,
                writable: true,
                enumerable: true
            },
            verantwoordelijke: {
                type: String,
                value: data.responsiblePerson,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
}

class CheeseMarks {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.isNew = data.updated == undefined ? true : false;
        Object.defineProperties(this, {
            gewijzigd: {
                value: data.updated,
                writable: false,
                enumerable: true
            },
            bakID: {
                value: data.batchID,
                writable: true
            },
            productID: {
                type: Number,
                value: data.productID,
                writable: true,
                enumerable: true
            },
            merkProductID: {
                type: Number,
                value: data.merkProductID,
                writable: true,
                enumerable: true
            },
            merktypeID: {
                type: Number,
                value: data.markTypeID,
                writable: true,
                enumerable: true
            },
            opmerking: {
                value: data.remark,
                writable: true
            },
            eindNummer: {
                type: Number,
                value: data.endNumber ?? data.endnumber,
                writable: true,
                enumerable: true
            },
            startNummer: {
                type: Number,
                value: data.startNumber ?? data.startnumber,
                writable: true,
                enumerable: true
            },
            startLetter: {
                type: String,
                value: data.startLetter ?? data.startletter,
                writable: true,
                enumerable: true
            },
            eindLetter: {
                type: String,
                value: data.endLetter ?? data.endletter,
                writable: true,
                enumerable: true
            },
            kazen: {
                type: Number,
                value: data.markCount,
                writable: true,
                enumerable: true
            },
            beginStockItemID: {
                type: Number,
                value: data.startStockItemID,
                writable: true,
                enumerable: true
            },
            eindStockItemID: {
                type: Number,
                value: data.endStockItemID,
                writable: true,
                enumerable: true
            },
            vormID: {
                type: Number,
                value: data.shapeID,
                writable: true,
                enumerable: true
            },
            vormGewicht: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            },
            subSeries: {
                value: data.cheeseMarkSeries
                    ? data.cheeseMarkSeries.map((x) => new CheeseMarks(x))
                    : [],
                writable: true,
                enumerable: true
            }
        });
        this.suggestion = this.startNummer == undefined;
        this.kazen = this.kazen
            ? this.kazen
            : this.eindNummer - this.startNummer + 1;
        //this.fillMarkSeries();
    }

    fillMarkSeries() {
        // van de subseries weten we de markstockid niet, vullen uit hoofdrecord
        for (let i = 0; i < this.subSeries.length; i++) {
            const obj = this.subSeries[i];
            obj.beginStockItemID =
                this.startLetter == obj.startLetter
                    ? this.beginStockItemID
                    : this.eindStockItemID;
            obj.eindStockItemID =
                this.endLetter == obj.endLetter
                    ? this.eindStockItemID
                    : this.beginStockItemID;
        }
    }

    CheckValidity(func) {
        let warnText = '';
        if (this.amount) {
            if (this.amount < 1 || this.amount > 1000)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_number', { ns: 'common' }),
                        val: this.amount
                    })
                );
        } else {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_amount', { ns: 'common' })
                })
            );
        }
        return warnText;
    }
}

class BatchStats {
    // alleen voor tonen
    constructor(data) {
        this.id = -1;
        this.updated = null;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.isNew = this.isNew ? this.isNew : this.updated == undefined;
        Object.defineProperties(this, {
            vormID: {
                value: data.shapeId,
                writable: true,
                enumerable: true
            },
            productID: {
                value: data.productID,
                writable: true,
                enumerable: true
            },
            gewijzigd: {
                value: data.updated,
                writable: false,
                enumerable: true
            },
            inPekelTijd: {
                value: DateHelper.convertedToDate(data.inbrine),
                writable: true,
                enumerable: true
            },
            uitPekelTijd: {
                value: DateHelper.convertedToDate(data.outbrine),
                writable: true,
                enumerable: true
            },
            kooi: {
                value: data.brinecage,
                writable: true,
                enumerable: true
            },
            laag: {
                value: data.brinelayer,
                writable: true,
                enumerable: true
            },
            defAantal: {
                value: data.defCount,
                writable: true,
                enumerable: true
            },
            aantal: {
                value: data.count,
                writable: true,
                enumerable: true
            },
            merkAantal: {
                value: data.markCount,
                writable: true,
                enumerable: true
            },
            kilos: {
                value: data.sum,
                writable: true,
                enumerable: true
            },
            gemGewcht: {
                value: data.avg,
                writable: true,
                enumerable: true
            },
            stdGewicht: {
                value: data.std,
                writable: true,
                enumerable: true
            },
            varGewicht: {
                value: data.var,
                writable: true,
                enumerable: true
            }
        });
    }
}

class BakIngredient {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.visible = true;
        this.nouse = false; //  niet echt goede oplossing
        this.klaarzetten = false; //  niet echt goede oplossing
        this.BatchID = null;
        this.StockItemID = null;
        this.Amountused = null;
        this.perUnitID = null;
        this.Usedate = null;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id ?? -1,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.updated ?? new Date(),
                writable: false,
                enumerable: true
            },
            bakID: {
                value: data.ba_id ?? data.batchID,
                writable: true,
                enumerable: true
            },
            productID: {
                value: data.productID ?? data.productid,
                writable: true,
                enumerable: true
            },
            ingredientCat: {
                value: data.ingredientcat,
                writable: true,
                enumerable: true
            },
            naam: {
                value: data.product,
                writable: true,
                enumerable: true
            },
            hoeveelheid: {
                value: data.amount ?? data.amountused,
                writable: true,
                enumerable: true
            },
            eenheid: {
                type: String,
                value: data.unit,
                writable: true,
                enumerable: true
            },
            factor: {
                value: data.basecalcfactor ?? 1,
                writable: true,
                enumerable: true
            },
            vanafLocatie: {
                value: data.location,
                writable: true,
                enumerable: true
            },
            partijNummer: {
                value: data.batchnumber,
                writable: true,
                enumerable: true
            },
            toegevoegdTijd: {
                value: data.used ?? data.usedate,
                writable: true,
                enumerable: true
            },
            toegevoegdTijdPrep: {
                value: DateHelper.convertedToTime(
                    DateHelper.convertedToDateTimeString(data.used)
                ),
                writable: true,
                enumerable: true
            },
            alternatief: {
                type: String,
                value: data.alternatives,
                writable: true,
                enumerable: true
            },
            alternatieven: {
                type: Array,
                value: data.alternatives ?? [],
                writable: true,
                enumerable: true
            },

            // voor opslaan
            partijID: {
                value: data.stockItemID,
                writable: true,
                enumerable: true
            },
            eenheidID: {
                value: data.perUnitID,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    PrepareAlternatives() {
        const arrayAltCT = [];
        // check of het een array is, anders kan de string verwerken
        if (Array.isArray(this.alternatieven)) {
            for (let i = 0; i < this.alternatieven.length; i++) {
                const altCT = this.alternatieven[i].SetForSave();
                arrayAltCT.push(altCT);
            }
        } else {
            const altKaasArray = this.alternatieven.split(';');
            for (let i = 0; i < altKaasArray.length; i++) {
                if (altKaasArray[i].indexOf(',') > 0) {
                    const altKaas = altKaasArray[i].split(',');
                    const ac = new AlternativeCheese({
                        productID: altKaas[0],
                        amount: altKaas[1]
                    });
                    arrayAltCT.push(ac);
                }
            }
        }
        return arrayAltCT;
    }

    SetForAdd() {
        const tempArr = this.PrepareAlternatives();
        const tijd = this.klaarzetten
            ? null
            : this.toegevoegdTijd ?? new Date();

        const BatchIngredientModel = {
            BatchID: this.bakID ?? this.bakId,
            ID: this.id,
            StockItemID: this.partijID,
            Amountused: this.hoeveelheid,
            PerUnitID: this.eenheidID,
            AlternativeCheeseTypes: tempArr,
            Usedate: DateHelper.localDateToUTC(tijd),
            Updated: this.gewijzigd
        };
        return BatchIngredientModel;
    }
    SetForDelete() {
        const RemoveBatchIngredientModel = {
            batchid: this.bakID ?? this.bakId,
            id: this.id,
            lastupdated: this.gewijzigd
        };
        return RemoveBatchIngredientModel;
    }
    CheckValidity(func) {
        let warnText = '';
        if (!this.nouse) {
            if (this.hoeveelheid) {
                if (this.hoeveelheid < 0 || this.hoeveelheid > 50000)
                    warnText = Helper.concatString(
                        warnText,
                        func('com_notplausible', {
                            ns: 'common',
                            prop: func('com_amount', { ns: 'common' }),
                            val: this.hoeveelheid
                        })
                    );
            } else {
                if (this.partijID)
                    warnText = Helper.concatString(
                        warnText,
                        func('com_isrequired', {
                            ns: 'common',
                            val: '',
                            prop: func('com_amount', { ns: 'common' })
                        })
                    );
            }
        }
        return warnText;
    }
}

class BakInfo {
    constructor(data) {
        this.id = -1;
        this.updated = null;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.updated,
                writable: false,
                enumerable: true
            },
            productieDatum: {
                value: DateHelper.convertedToDate(data.bookdate),
                writable: false,
                enumerable: true
            },
            bakno: {
                value: data.number,
                writable: true,
                enumerable: true
            },
            bakgroepInternID: {
                value: data.batchgroup,
                writable: true,
                enumerable: true
            },
            opmerking: {
                value: data.remark,
                writable: true,
                enumerable: true
            },
            vorm: {
                value: data.shape,
                writable: true,
                enumerable: true
            },
            kaasnaam: {
                value: data.cheesetype,
                writable: true,
                enumerable: true
            },
            aantal: {
                value: data.amounts,
                writable: true,
                enumerable: true
            },
            inpekeltijd: {
                value: DateHelper.convertedToDateTimeShort(data.inbrine),
                writable: false,
                enumerable: true
            },
            uitpekeltijd: {
                value: DateHelper.convertedToDateTimeShort(data.outbrine),
                writable: false,
                enumerable: true
            },
            uitpekeltijdplan: {
                value: DateHelper.convertedToDateTimeShort(
                    data.outbrineplanned
                ),
                writable: false,
                enumerable: true
            },
            inpekeltijdOri: {
                value: data.inbrine,
                writable: false,
                enumerable: true
            },
            uitpekeltijdOri: {
                value: data.outbrine,
                writable: false,
                enumerable: true
            },
            uitpekeltijdplanOri: {
                value: data.outbrineplanned,
                writable: false,
                enumerable: true
            },
            uitpekel: {
                value:
                    data.uitpekeltijd != undefined
                        ? data.uitpekeltijd
                        : data.uitpekeltijdplan,
                writable: true,
                enumerable: true
            },
            urenInPekel: {
                value: data.hoursinbrine,
                writable: true,
                enumerable: true
            },
            geblokkeerd: {
                value: data.blocked,
                writable: true,
                enumerable: true
            },
            recIngredientsAmount: {
                type: Number,
                value: data.recingredients,
                writable: true,
                enumerable: true
            },
            batchIngredientsAmount: {
                type: Number,
                value: data.batchingredients,
                writable: true,
                enumerable: true
            },
            ing_toegevoegd: {
                type: Number,
                value: 0,
                writable: true,
                enumerable: true
            },
            kooilaag: {
                type: String,
                value: '',
                writable: true,
                enumerable: true
            },
            kooi: {
                type: String,
                value: data.cage,
                writable: true,
                enumerable: true
            },
            laag: {
                type: String,
                value: data.layer,
                writable: true,
                enumerable: true
            },
            verantwoordelijke: {
                type: String,
                value: data.responsibleperson,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
        this.ing_toegevoegd =
            this.recIngredientsAmount - this.batchIngredientsAmount;
        this.uitgepekeld = this.uitpekeltijd != undefined;
        this.uitpekel =
            this.uitpekeltijd != undefined
                ? this.uitpekeltijd
                : this.uitpekeltijdplan;
        this.ingepekeld = this.inpekeltijd != undefined;

        this.uitpekelOri =
            this.uitpekeltijdOri != undefined
                ? this.uitpekeltijdOri
                : this.uitpekeltijdplanOri;
        this.kooilaag = this.kooi
            ? Helper.stringformat('{0}-{1}', this.kooi, this.laag ?? '?')
            : '-';
    }
}

class BakInfoHist extends BakInfo {
    constructor(data) {
        super(data);
        Object.defineProperties(this, {});
    }
}

class BakDefaults {
    constructor(data) {
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                type: Number,
                value: data.batchID,
                writable: false,
                enumerable: true
            },
            productID: {
                type: Number,
                value: data.productID,
                writable: false,
                enumerable: true
            },
            vormID: {
                type: Number,
                value: data.shapeID,
                writable: false,
                enumerable: true
            },
            gewicht: {
                value: data.shapeWeight ?? 0,
                writable: false,
                enumerable: true
            },
            melkgebruik: {
                value: data.milkUsage ?? 1,
                writable: false,
                enumerable: true
            },
            merkTypeID: {
                type: Number,
                value: data.markTypeID,
                writable: false,
                enumerable: true
            },
            merkTypeProductID: {
                type: Number,
                value: data.markTypeProductID,
                writable: false,
                enumerable: true
            }
        });
    }
    CheckValidity() {
    }
}
class AlternativeCheese {
    constructor(data) {
        this.id = -1;
        this.updated = null;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                type: Number,
                value: data.productID,
                writable: false,
                enumerable: true
            },
            productID: {
                type: Number,
                value: data.productID,
                writable: true,
                enumerable: true
            },
            aantal: {
                type: Number,
                value: data.amount,
                writable: true,
                enumerable: true
            },
            naam: {
                type: String,
                value: data.productName,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    SetForSave() {
        this.Amount = this.aantal;
        const AlternativeCheeseType = {
            Amount: this.Amount,
            productName: this.naam,
            productID: this.productID
        };
        return AlternativeCheeseType;
    }
}

