<template>
    <b-card no-body>
        <b-card-header class="container-fluid">
            <b-col :sm="10" class="mx-0 px-0 my-0 py-0">
                <b-form-checkbox v-model="showRecPattern">
                    {{ $t('rec_pattern', { ns: 'recurrency' }) }}
                </b-form-checkbox>
            </b-col>
            <b-col :sm="2" class="mx-0 px-0 my-0 py-0">
                <b-button
                    v-if="editAllowed && hasRightToDelete"
                    @click="verwijderItem"
                    class="bgc_aux_alarm float-right"
                    ><font-awesome-icon icon="trash-alt" size="1x" />
                </b-button>
            </b-col>
        </b-card-header>

        <b-alert
            :show="dismissCountDown"
            v-model="showDismissibleAlert"
            :variant="variantType"
            dismissible
            @dismissed="resetVariant"
        >
            {{ alertText }}
        </b-alert>
        <b-card-text v-if="showRecPattern">
            <b-col sm="3" class="mx-0 px-0 my-0 py-0">
                <b-card>
                    <b-form-group>
                        <b-form-radio-group
                            v-model="selected"
                            :options="options"
                            name="radios-stacked"
                            stacked
                        ></b-form-radio-group>
                    </b-form-group>
                </b-card>
            </b-col>
            <b-col
                sm="9"
                class="mx-0 px-0 my-0 py-0"
                style="border-left: 1px solid black; margin: 0 7.5px"
            >
                <card-daily
                    v-if="selected == constDaily"
                    :interval="localPattern.pauze"
                    :type="localPattern.type"
                    :editAllowed="editAllowed"
                    @changed="setDayRecurrence"
                />
                <card-weekly
                    v-if="selected == constWeekly"
                    :dagen="localPattern.dagen"
                    :interval="localPattern.pauze"
                    :editAllowed="editAllowed"
                    @changed="setWeekRecurrence"
                />
                <card-monthly
                    v-if="selected == constMothly"
                    :weken="localPattern.weken"
                    :dagen="localPattern.dagen"
                    :maanddagen="localPattern.maanddagen"
                    :interval="localPattern.pauze"
                    :editAllowed="editAllowed"
                    @changed="setMonthRecurrence"
                />
                <card-yearly
                    v-if="selected == constYearly"
                    :weken="localPattern.weken"
                    :dagen="localPattern.dagen"
                    :maanden="localPattern.maanden"
                    :maanddagen="localPattern.maanddagen"
                    :interval="localPattern.pauze"
                    :editAllowed="editAllowed"
                    @changed="setYearRecurrence"
                />
            </b-col>
        </b-card-text>
        <b-card-text v-if="showRecPattern">
            <b-card>
                <b-col :sm="6"> {{ datumLaatste }} </b-col>
                <b-col :sm="6"> {{ datumVolgende }} </b-col>
            </b-card>
        </b-card-text>
    </b-card>
</template>

<script>
import { RecurrencyPattern, RECURRENCY_ENUM } from '../../models/Recurrency';
import DateHelper from '../../models/DateHelper';
import CardDaily from './CardDaily.vue';
import CardWeekly from './CardWeekly.vue';
import CardMonthly from './CardMonthly.vue';
import CardYearly from './CardYearly.vue';

export default {
    name: 'RecurrencyBase',
    data() {
        return {
            showRecPattern: false,
            selected: RECURRENCY_ENUM.Weekly,
            constDaily: RECURRENCY_ENUM.Daily,
            constWeekly: RECURRENCY_ENUM.Weekly,
            constMothly: RECURRENCY_ENUM.Monthly,
            constYearly: RECURRENCY_ENUM.Yearly,
            hasRightToDelete: true,
            options: [
                {
                    text: this.$t('rec_daily', { ns: 'recurrency' }),
                    value: RECURRENCY_ENUM.Daily
                },
                {
                    text: this.$t('rec_weekly', { ns: 'recurrency' }),
                    value: RECURRENCY_ENUM.Weekly
                },
                {
                    text: this.$t('rec_monthly', { ns: 'recurrency' }),
                    value: RECURRENCY_ENUM.Monthly
                },
                {
                    text: this.$t('rec_yearly', { ns: 'recurrency' }),
                    value: RECURRENCY_ENUM.Yearly
                }
            ],
            localPattern: null,
            approvedText: 'danger',
            dismissCountDown: 5,
            alertText: '',
            showDismissibleAlert: false,
            savePattern: null
        };
    },
    components: {
        CardDaily,
        CardWeekly,
        CardMonthly,
        CardYearly
    },
    model: {
        prop: 'pattern',
        event: 'input'
    },
    props: {
        pattern: {
            type: RecurrencyPattern,
            required: false
        },
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        datumLaatste() {
            const datum =  DateHelper.convertedToDate(this.localPattern.laatstekeer);
            return this.$t('rec_lasttime', { ns: 'recurrency', val: datum })
        },
        datumVolgende() {
            const datum =   DateHelper.convertedToDate(this.localPattern.volgendekeer);
            return this.$t('rec_nexttime', { ns: 'recurrency', val: datum })
        },
        variantType() {
            return this.approvedText;
        }
    },
    methods: {
        showAlert(tekst) {
            this.dismissCountDown = 5;
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        resetVariant() {
            this.approvedText = 'danger';
        },
        setDayRecurrence(data) {
            // bepaal vandaag voor de weekday
            const now = new Date();
            const day = now.getDay();
            data.dagen = [day];
            this.savePattern = this.localPattern.SaveDaily(data);
            this.$emit('changed', this.savePattern);
        },
        setWeekRecurrence(data) {
            this.savePattern = this.localPattern.SaveWeekly(data);
            this.$emit('changed', this.savePattern);
        },
        setMonthRecurrence(data) {
            this.savePattern = this.localPattern.SaveMonthly(data);
            this.$emit('changed', this.savePattern);
        },
        setYearRecurrence(data) {
            this.savePattern = this.localPattern.SaveYearly(data);
            this.$emit('changed', this.savePattern);
        },
        verwijderItem() {
            const clone = structuredClone(this.localPattern);
            clone.deleted = true;
            this.$emit('deleted', clone);
            this.savePattern = null;
        }
    },
    mounted() {},
    created() {
        this.showRecPattern = this.pattern;
        this.localPattern =
            this.pattern ??
            new RecurrencyPattern({ type: RECURRENCY_ENUM.Weekly });
        this.selected = this.localPattern.displayType;
    }
};
</script>

<style scoped></style>
