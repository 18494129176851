var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticStyle:{"margin-top":"60px"},attrs:{"fluid":""}},[_c('b-row',{staticClass:"form-inline mx-0 px-0 my-0 py-0"},[_c('b-col',{staticClass:"form-inline mx-0 px-0 my-0 py-0",attrs:{"sm":"10"}},[_c('h1',{on:{"click":_vm.flipClosed}},[_vm._v(" "+_vm._s(_vm.title)+"   "),_c('b-button',[_vm._v(" "+_vm._s(_vm.btnTekst)+" ")])],1),_vm._v("   "),(!_vm.isVersion10)?_c('b-button',{staticClass:"bgc_aux_normal",on:{"click":function($event){return _vm.flipKaas()}}},[_vm._v(_vm._s(_vm.btnFlipKaas)+" ")]):_vm._e(),_vm._v("   "),_c('b-button',{staticClass:"bgc_aux_unimportant",on:{"click":function($event){return _vm.scan()}}},[_c('font-awesome-icon',{attrs:{"icon":"barcode","size":"1x"}})],1),_vm._v("   "),_c('b-button',{staticClass:"bgc_aux_unimportant",on:{"click":function($event){return _vm.makeList()}}},[_c('font-awesome-icon',{attrs:{"icon":"edit","size":"1x"}})],1),_vm._v("   ")],1),_c('b-col',{staticClass:"mx-0 px-0 my-0 py-0",attrs:{"sm":"2"}},[_c('b-form-checkbox',{staticClass:"float-right",model:{value:(_vm.landscape),callback:function ($$v) {_vm.landscape=$$v},expression:"landscape"}},[_vm._v(_vm._s(_vm.$t('com_landscape', { ns: 'common' }))+" ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[(_vm.showFilter)?_c('div',[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_filter', { ns: 'common' }),"label-for":"filter-input","label-cols-sm":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":_vm.$t('com_typetosearch', {
                                            ns: 'common'
                                        })},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v(_vm._s(_vm.$t('com_wis', { ns: 'common' })))])],1)],1),_c('b-input-group',{attrs:{"size":"sm"}},[(
                                        !_vm.gesloten &&
                                        _vm.checkRight('StockIn') &&
                                        !_vm.isVersion10
                                    )?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.createNewItem}},[_c('font-awesome-icon',{attrs:{"icon":"folder-plus","size":"1x"}}),_vm._v(" "+_vm._s(_vm.strNieuw)+" ")],1):_vm._e(),_vm._v("   "),(!this.showkaas && !_vm.isVersion10)?_c('b-form-checkbox',{attrs:{"value":"dofiltertht","unchecked-value":"dontfiltertht"},on:{"change":function($event){return _vm.doFilterTHT(_vm.value)}},model:{value:(_vm.filterTHT),callback:function ($$v) {_vm.filterTHT=$$v},expression:"filterTHT"}},[_vm._v(" "+_vm._s(_vm.strTHTkritisch)+" ")]):_vm._e()],1)],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_perpagina', { ns: 'common' }),"label-for":"per-page-select","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pageOptions,"size":"sm"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1):_c('div',[_c('b-input-group',[(
                            !_vm.gesloten &&
                            _vm.checkRight('StockIn') &&
                            !_vm.isVersion10
                        )?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.createNewItem}},[_c('font-awesome-icon',{attrs:{"icon":"folder-plus","size":"1x"}}),_vm._v(" "+_vm._s(_vm.strNieuw)+" ")],1):_vm._e(),_vm._v("   "),(!this.showkaas && !_vm.isVersion10)?_c('b-form-checkbox',{attrs:{"value":"dofiltertht","unchecked-value":"dontfiltertht"},on:{"changed":function($event){return _vm.doFilterTHT()}},model:{value:(_vm.filterTHT),callback:function ($$v) {_vm.filterTHT=$$v},expression:"filterTHT"}},[_vm._v(" "+_vm._s(_vm.strTHTkritisch)+" ")]):_vm._e()],1)],1),_c('b-table',{attrs:{"items":_vm.groupedDisplayList,"fields":_vm.visibleFields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"filter-included-fields":_vm.filterOn,"stacked":"md","show-empty":"","empty-text":_vm.$t('com_geen_data', { ns: 'common' }),"small":"","striped":""},on:{"filtered":_vm.onFiltered,"row-clicked":_vm.toonVoorraad},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [(
                            !_vm.gesloten &&
                            !_vm.pekel(row.item) &&
                            !_vm.tienplus(row.item) &&
                            _vm.checkRight('StockIn') &&
                            !_vm.isVersion10
                        )?_c('b-button',{staticClass:"bgc_aux_normal",attrs:{"size":"sm"},on:{"click":function($event){return _vm.addItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"folder-plus","size":"1x"}})],1):_vm._e(),_c('b-button',{staticClass:"bgc_aux_unknown",attrs:{"size":"sm"},on:{"click":function($event){return _vm.toonVoorraad(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"info-circle","size":"1x"}})],1)]}},{key:"row-details",fn:function(){return [_c('stock-items',{attrs:{"stocklist":_vm.stockList,"gesloten":_vm.gesloten,"pekel":_vm.isInPekel,"tienplus":_vm.isTienPLus,"tienpluskaas":_vm.isTienPLusKaas,"landscape":_vm.landscape}})]},proxy:true}])})],1)],1),(_vm.detailsVisible)?_c('item-stock-modal',{attrs:{"inItem":_vm.currentItem,"editAllowed":true},on:{"hide":_vm.resetISM},model:{value:(_vm.detailsVisible),callback:function ($$v) {_vm.detailsVisible=$$v},expression:"detailsVisible"}}):_vm._e(),(_vm.showScanner)?_c('scan-barcode',{on:{"result":_vm.showDetailStock,"hide":_vm.resetSB},model:{value:(_vm.showScanner),callback:function ($$v) {_vm.showScanner=$$v},expression:"showScanner"}}):_vm._e(),(_vm.showDetails)?_c('item-details-modal',{attrs:{"stockItemId":_vm.itemNumber},on:{"hide":_vm.resetIDM},model:{value:(_vm.showDetails),callback:function ($$v) {_vm.showDetails=$$v},expression:"showDetails"}}):_vm._e(),(_vm.makeDelList)?_c('quick-list-modal',{on:{"hide":_vm.reset},model:{value:(_vm.makeDelList),callback:function ($$v) {_vm.makeDelList=$$v},expression:"makeDelList"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }