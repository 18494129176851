<template>
    <b-card>
        <b-tabs card>
            <b-tab :title="$t('an_result', { ns: 'analytics' })">
                <b-card-text>
                    <b-row>
                        <b-col sm="6">
                            <b-row>
                                <b-col sm="3">
                                    <label for="dateOnderzoek">{{
                                        $t('an_meetdatum', {
                                            ns: 'analytics'
                                        })
                                    }}</label>
                                </b-col>
                                <b-col sm="9">
                                    <analytics-date-time
                                        id="dateOnderzoek"
                                        v-model="datumOnderzoek"
                                        v-bind:editAllowed="editAllowed"
                                    >
                                    </analytics-date-time>
                                </b-col>
                                <b-col sm="3">
                                    <label for="dateBoeking"
                                        >{{
                                            $t('an_plandatum', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <analytics-date-time
                                        id="dateBoeking"
                                        v-model="datumBoeking"
                                        v-bind:editAllowed="editAllowed"
                                    >
                                    </analytics-date-time>
                                </b-col>
                                <b-col sm="3">
                                    <label for="input-itemnaam"
                                        >{{
                                            $t('an_subject', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <entity-object-selector
                                        v-model="localItem.meetplekID"
                                        :editAllowed="editAllowed"
                                    ></entity-object-selector>
                                </b-col>

                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_uitvoerder', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <lookup-combo
                                        v-model="localItem.uitvoerderID"
                                        v-bind:combotype="lctypeExecutor"
                                        :disabled="!editAllowed"
                                    ></lookup-combo>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="6">
                            <b-row>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_meting', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <measurement-combo
                                        v-model="locMaesureObject"
                                        v-bind:editAllowed="editAllowed"
                                    ></measurement-combo>
                                </b-col>
                                <b-col sm="3">
                                    <label>Grensen:</label>
                                </b-col>
                                <b-col sm="9">
                                    <b-input-group class="form-inline">
                                        <b-input-group
                                            :prepend="
                                                $t('com_min', { ns: 'common' })
                                            "
                                        >
                                            <b-form-input
                                                :placeholder="
                                                    $t('com_minimum', {
                                                        ns: 'common'
                                                    })
                                                "
                                                type="number"
                                                v-model.number="
                                                    localItem.minlimiet
                                                "
                                                :disabled="!editAllowed"
                                            ></b-form-input>
                                        </b-input-group>
                                        <b-input-group
                                            :prepend="
                                                $t('com_max', { ns: 'common' })
                                            "
                                        >
                                            <b-form-input
                                                :placeholder="
                                                    $t('com_maximum', {
                                                        ns: 'common'
                                                    })
                                                "
                                                type="number"
                                                v-model.number="
                                                    localItem.maxlimiet
                                                "
                                                :disabled="!editAllowed"
                                            ></b-form-input>
                                        </b-input-group>
                                    </b-input-group>
                                </b-col>
                                <b-form-textarea
                                    v-model="localItem.opmerking"
                                    :placeholder="
                                        $t('com_opmerking', { ns: 'common' })
                                    "
                                    rows="3"
                                    max-rows="6"
                                    :disabled="!editAllowed"
                                ></b-form-textarea>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-tab>
            <b-tab @click="loadActData()" lazy>
                <template #title>
                    <b-spinner
                        v-if="showSpinner"
                        type="border"
                        small
                    ></b-spinner>
                    {{ $t('com_activitylog', { ns: 'common' }) }}
                </template>
                <b-card-text>
                    <b-table
                        striped
                        responsive
                        :fields="fieldsActiviteiten"
                        :items="activiteitenLijst"
                        primary-key
                    >
                    </b-table>
                </b-card-text>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
import { MaesureObject, Maesurement } from '../../models/Anayltics';
import LookupCombo from './LookupCombo.vue';
import EntityObjectSelector from './EntityObjectSelector.vue';
import AnalyticsDateTime from './AnalyticsDateTime.vue';
import MeasurementCombo from './MeasurementCombo.vue';
import { loadItem } from './utils';
export default {
    name: 'MeasurementInfo',
    data: function () {
        return {
            lctypeExecutor: window.constants.LCA_EXECUTOR,
            lctypeQunit: window.constants.LCA_QUNIT,
            lctypeIQ: window.constants.LCA_INVQUANTITY,
            showSpinner: false,
            fieldsActiviteiten: [
                {
                    key: 'gemaakt',
                    label: this.$t('prod_gemaakt', { ns: 'production' })
                },
                {
                    key: 'actie',
                    label: this.$t('prod_activity', { ns: 'production' })
                },
                {
                    key: 'persoon',
                    label: this.$t('prod_loggedin', { ns: 'production' })
                }
            ],
            activityList: [],
            isChanged: false,
            naam: null,
            locItem: null,
            locMaesureObject: null,
            returnObject: null,
            editAllowed: false,
            datumBoeking: null,
            datumOnderzoek: null
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inItem: {
            type: Maesurement,
            required: false
        }
    },
    components: {
        LookupCombo,
        MeasurementCombo,
        EntityObjectSelector,
        AnalyticsDateTime
    },
    watch: {},
    computed: {
        localItem() {
            return this.locItem;
        },
        activiteitenLijst() {
            return this.activityList;
        }
    },
    methods: {
        setDatumOnderzoek(value) {
            this.datumOnderzoek = value;
            this.setChanged();
        },
        setDatumBoeking(value) {
            this.datumBoeking = value;
            this.setChanged();
        },
        setChanged() {
            this.isChanged = true;
        },
        setMaesurement(item) {
            if (item) {
                this.localItem.waarde = item.hoeveelheid;
                this.localItem.onderzoeksgrootheidID = item.grootheidid;
                this.localItem.eenheidID = item.eenheidid;
            }
        },
        async loadActData() {
            this.showSpinner = true;
            this.activityList = await loadItem(
                'analytics/loadMaesurementActiviteitenLijst',
                {
                    measurementID: this.localItem.id
                },
                this.$t
            );
            this.showSpinner = false;
        }
    },
    mounted() {},
    created() {
        if (this.inItem) {
            this.locItem = this.inItem;
            this.datumOnderzoek = this.locItem.meettijdstip;
            this.datumBoeking = this.locItem.boektijdstip;
            this.locMaesureObject = new MaesureObject();
            this.locMaesureObject.hoeveelheid = this.locItem.waarde;
            this.locMaesureObject.grootheidid =
                this.locItem.onderzoeksgrootheidID;
            this.locMaesureObject.eenheidid = this.locItem.eenheidID;
            this.naam = this.locItem.naam;
        }
    }
};
</script>

<style scoped></style>

