var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticStyle:{"margin-top":"60px"},attrs:{"fluid":""}},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"form-inline"},[_c('external-link-selector',{on:{"inputtype":_vm.setType,"inputsubtype":_vm.setSubType,"input":_vm.reloadData},model:{value:(_vm.anID),callback:function ($$v) {_vm.anID=$$v},expression:"anID"}}),_vm._v("   ")],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-card',[_c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{key:1,attrs:{"title":_vm.$t('com_product', { ns: 'common' }),"active":""}},[(_vm.showFilter)?_c('div',[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_filter', {
                                                ns: 'common'
                                            }),"label-for":"filter-input","label-cols-sm":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":_vm.$t('com_typetosearch', {
                                                        ns: 'common'
                                                    })},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v(_vm._s(_vm.$t('com_wis', { ns: 'common' })))])],1)],1)],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_perpagina', {
                                                ns: 'common'
                                            }),"label-for":"per-page-select","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pageOptions,"size":"sm"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1):_vm._e(),_c('b-table',{attrs:{"items":_vm.displayList,"fields":_vm.visibleFields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"filter-included-fields":_vm.filterOn,"stacked":"md","show-empty":"","empty-text":_vm.$t('com_geen_data', { ns: 'common' }),"small":"","striped":""},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [(
                                        _vm.checkRight(
                                            'ChangeExternalCommnication'
                                        )
                                    )?_c('b-button',{staticClass:"bgc_aux_alarm",attrs:{"size":"sm"},on:{"click":function($event){return _vm.unlinkItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt","size":"1x"}})],1):_vm._e(),(
                                        _vm.checkRight(
                                            'ChangeExternalCommnication'
                                        )
                                    )?_c('b-dropdown',{ref:"dropdown",attrs:{"id":"dropdown-form","text":"...","size":"sm"},on:{"show":function($event){return _vm.setStartValues(row.item)}}},[_c('div',{staticClass:"fluid-container"},[_c('b-dropdown-form',[_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('com_koppel', {
                                                            ns: 'common'
                                                        }),"label-for":"dropdown-form-correct"}},[_c('b-form-select',{attrs:{"options":_vm.tobeLinked},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":""}},[_vm._v("-- "+_vm._s(_vm.$t( 'com_selecteer', { ns: 'common' } ))+" --")])]},proxy:true}],null,true),model:{value:(_vm.externalID),callback:function ($$v) {_vm.externalID=$$v},expression:"externalID"}}),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveLink(
                                                                row.item
                                                            )}}},[_vm._v("Ok")])],1)],1),_c('b-dropdown-divider')],1)],1)]):_vm._e()]}}])})],1),_c('b-tab',{attrs:{"lazy":""},on:{"click":function($event){return _vm.loadImportedData()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.showSpinner)?_c('b-spinner',{attrs:{"type":"border","small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.lijstTitel)+" ")]},proxy:true}])},[(_vm.showFilter1)?_c('div',[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_filter', {
                                                ns: 'common'
                                            }),"label-for":"filter-input","label-cols-sm":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":_vm.$t('com_typetosearch', {
                                                        ns: 'common'
                                                    })},model:{value:(_vm.filter1),callback:function ($$v) {_vm.filter1=$$v},expression:"filter1"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter1},on:{"click":function($event){_vm.filter1 = ''}}},[_vm._v(_vm._s(_vm.$t('com_wis', { ns: 'common' })))])],1)],1)],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_perpagina', {
                                                ns: 'common'
                                            }),"label-for":"per-page-select","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pageOptions,"size":"sm"},model:{value:(_vm.perPage1),callback:function ($$v) {_vm.perPage1=$$v},expression:"perPage1"}}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows1,"per-page":_vm.perPage1,"align":"fill","size":"sm"},model:{value:(_vm.currentPage1),callback:function ($$v) {_vm.currentPage1=$$v},expression:"currentPage1"}})],1)],1)],1)],1):_vm._e(),_c('b-card-text',[_c('b-table',{attrs:{"striped":"","responsive":"","fields":_vm.fieldsToon,"items":_vm.inLijst,"primary-key":"","current-page":_vm.currentPage1,"per-page":_vm.perPage1,"filter":_vm.filter1,"filter-included-fields":_vm.filterOn1,"show-empty":"","empty-text":_vm.$t('com_geen_data', { ns: 'common' })},on:{"filtered":_vm.onFiltered1}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }