var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",attrs:{"id":"modal-norm","size":"lg","title":this.formtitle,"visible":_vm.visible},on:{"hidden":_vm.hide,"ok":_vm.handleOk}},[_c('b-alert',{attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('div',[_c('b-tabs',{attrs:{"card":""}},[_c('b-card',[_c('b-tab',{attrs:{"title":_vm.$t('an_normen', {
                            ns: 'analytics'
                        })}},[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-itemnaam"}},[_vm._v(_vm._s(_vm.$t('an_subject', { ns: 'analytics' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('lookup-combo',{attrs:{"combotype":_vm.lctypeEO,"disabled":_vm.fixEO},on:{"change":_vm.setChanged},model:{value:(_vm.localItem.meetplekID),callback:function ($$v) {_vm.$set(_vm.localItem, "meetplekID", $$v)},expression:"localItem.meetplekID"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('an_meting', { ns: 'analytics' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('lookup-combo',{attrs:{"combotype":_vm.lctypeIQ,"disabled":_vm.fixIQ},on:{"change":_vm.setChanged},model:{value:(
                                        _vm.localItem.onderzoeksgrootheidID
                                    ),callback:function ($$v) {_vm.$set(_vm.localItem, "onderzoeksgrootheidID", $$v)},expression:"\n                                        localItem.onderzoeksgrootheidID\n                                    "}})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('an_norm', { ns: 'analytics' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('an_hoeveelheid', {
                                            ns: 'analytics'
                                        }),"type":"number","step":"0.1","disabled":!_vm.editAllowed},on:{"change":_vm.setChangedNorm},model:{value:(_vm.localItem.normwaarde),callback:function ($$v) {_vm.$set(_vm.localItem, "normwaarde", _vm._n($$v))},expression:"localItem.normwaarde"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('an_grenzen', { ns: 'analytics' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('limit-formula-combo',{key:"rerenderkey",attrs:{"normvalue":_vm.localItem.normwaarde,"editAllowed":_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(_vm.localItem.formule),callback:function ($$v) {_vm.$set(_vm.localItem, "formule", $$v)},expression:"localItem.formule"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('com_actie', { ns: 'common' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('an_verwijsbeschrijving', {
                                            ns: 'analytics'
                                        })},on:{"change":_vm.setChanged},model:{value:(_vm.localItem.actie),callback:function ($$v) {_vm.$set(_vm.localItem, "actie", $$v)},expression:"localItem.actie"}})],1)],1),_c('recurrency-base',{attrs:{"editAllowed":_vm.editAllowed,"pattern":_vm.localItem.herhaling},on:{"changed":_vm.setChangePattern,"deleted":_vm.setDeletePattern}})],1)],1),_c('b-tab',{attrs:{"lazy":""},on:{"click":function($event){return _vm.loadActivities()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.showSpinner)?_c('b-spinner',{attrs:{"type":"border","small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('com_activitylog', { ns: 'common' }))+" ")]},proxy:true}])},[_c('b-card-text',[_c('b-table',{attrs:{"striped":"","responsive":"","fields":_vm.fieldsActiviteiten,"items":_vm.activiteitenLijst,"primary-key":""}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }