<template>
    <div>
        <b-dropdown
            id="dropdown-divider"
            ref="dropdown"
            text="-"
            menu-class="w-100"
            class="w-100"
            @hide="handleHide($event)"
        >
            <template>
                <b-row>
                    <b-col cols="12" class="my-2">
                        {{ $t('com_datum', { ns: 'common' }) }}
                        <b-form-datepicker
                            :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric'
                            }"
                            locale="nl"
                            v-model="datum"
                            @input="set_input"
                            @shown="isCloseable = false"
                        ></b-form-datepicker>
                    </b-col>
                    <b-col>
                        {{ $t('com_tijd', { ns: 'common' }) }}
                        <b-form-timepicker
                            v-model="tijd"
                            locale="nl"
                            @input="set_input"
                            @shown="isCloseable = false"
                        ></b-form-timepicker>
                    </b-col>
                </b-row>
                <b-dropdown-item @click="closeMe()">{{
                    $t('com_close', { ns: 'common' })
                }}</b-dropdown-item>
            </template>
        </b-dropdown>
    </div>
</template>

<script>
import DateHelper from '../../models/DateHelper';
export default {
    name: 'CheeseTijd',
    data: function () {
        return {
            errorMessage: '',
            datum: null,
            tijd: null,
            isCloseable: false
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {}
    },
    watch: {
        selected() {
            //this.datum = DateHelper.convertedToDateTimeString(this.selected);
            this.datum = DateHelper.convertedToDateStringFromUTC(this.selected);
            this.tijd = DateHelper.convertedToTime(this.selected);
        }
    },
    computed: {
        tijdVan() {
            return DateHelper.convertedToTime(this.selected);
        },
        localSelected() {
            return this.datum;
        }
    },
    methods: {
        set_input: function () {
            if (this.datum && this.tijd) {
                const temptijd = DateHelper.convertedFromTimepicker(
                    this.datum,
                    this.tijd
                );
                this.$emit('change', temptijd);
            }
        },
        closeMe() {
            this.set_input();
            this.isCloseable = true;
            this.$refs.dropdown.hide();
        },
        handleHide(bvEvent) {
            if (!this.isCloseable) {
                bvEvent.preventDefault();
            } else {
                this.$refs.dropdown.hide();
            }
        }
    },
    mounted() {
        this.isCloseable = false;
        //this.datum = DateHelper.convertedToDateTimeString(this.selected);
        this.datum = DateHelper.convertedToDateStringFromUTC(this.selected);
        this.tijd = DateHelper.convertedToTime(this.selected);
        this.set_input();
    },
    created: function () {}
};
</script>

<style scoped></style>

