<template>
    <b-container fluid style="margin-top: 60px">
        <h1 v-on:click="flipPlan">
            {{ title }} &nbsp;
            <b-button>
                {{ btnTekst }}
            </b-button>
        </h1>
        <b-row>
            <b-col sm="8">
                <period-selector
                    v-model="selectDays"
                    v-bind:periodDays="periodeDagen"
                    v-bind:slackTillDate="slackDagen"
                    @change="setNewDates"
                />
            </b-col>
            <b-col sm="3">
                <b-row>
                    <b-col sm="11">
                        <b-button
                            @click="loadData()"
                            class="bgc_aux_normal"
                            v-b-tooltip.hover
                            :title="$t('com_showdata', { ns: 'common' })"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                    </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
            <b-col sm="1">
                <b-row class="float-right">
                    <b-col sm="11"> </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
        </b-row>

        <div slot="pdfdata">
            <b-row no-gutters>
                <b-col sm="12">
                    <b-col lg="6" class="my-1">
                        <b-form-group
                            :label="$t('com_filter', { ns: 'common' })"
                            label-for="filter-input"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <b-input-group size="sm">
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    :placeholder="
                                        $t('com_typetosearch', { ns: 'common' })
                                    "
                                ></b-form-input>

                                <b-input-group-append>
                                    <b-button
                                        :disabled="!filter"
                                        @click="filter = ''"
                                        >{{
                                            $t('com_wis', { ns: 'common' })
                                        }}</b-button
                                    >
                                </b-input-group-append>
                            </b-input-group>
                            <b-button
                                v-if="
                                    allowEdit &&
                                    checkRight('AnalyticsMeasurementCreate')
                                "
                                size="sm"
                                @click="createNewItem()"
                                class="bgc_aux_normal"
                                ><font-awesome-icon
                                    icon="folder-plus"
                                    size="1x"
                                />
                            </b-button>

                            &nbsp;

                            <b-button
                                v-if="checkRight('AnalyticsMeasurementCreate')"
                                size="sm"
                                @click="scan()"
                                class="bgc_aux_unimportant"
                                ><font-awesome-icon icon="barcode" size="1x" />
                            </b-button>
                        </b-form-group>
                    </b-col>

                    <b-col lg="6" class="my-1">
                        <b-form-group
                            :label="$t('com_perpagina', { ns: 'common' })"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                            ></b-pagination>
                        </b-form-group>
                    </b-col>
                    <b-table
                        :items="displayList"
                        :fields="visibleFields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        stacked="md"
                        show-empty
                        :empty-text="$t('com_geen_data', { ns: 'common' })"
                        small
                        striped
                        @filtered="onFiltered"
                    >
                        <template v-slot:cell(actions)="row">
                            <div class="form-inline">
                                <b-button
                                    v-if="
                                        checkRight('AnalyticsMeasurementCreate')
                                    "
                                    size="sm"
                                    @click="createNewItem(row.item)"
                                    class="bgc_aux_normal"
                                    ><font-awesome-icon
                                        icon="folder-plus"
                                        size="1x"
                                    />
                                </b-button>

                                <b-button
                                    v-if="
                                        checkRight('AnalyticsMeasurementUpdate')
                                    "
                                    size="sm"
                                    @click="bewerkItem(row.item)"
                                    class="bgc_aux_warning"
                                    ><font-awesome-icon icon="edit" size="1x" />
                                </b-button>
                                <b-button
                                    v-if="
                                        showPlan &&
                                        checkRight('AnalyticsMeasurementDelete')
                                    "
                                    size="sm"
                                    @click="verwijderItem(row.item)"
                                    class="bgc_aux_alarm"
                                    ><font-awesome-icon
                                        icon="trash-alt"
                                        size="1x"
                                    />
                                </b-button>
                                <b-button
                                    size="sm"
                                    @click="infoItem(row.item)"
                                    class="bgc_aux_unknown"
                                    ><font-awesome-icon
                                        icon="info-circle"
                                        size="1x"
                                    />
                                </b-button>
                            </div>
                        </template>
                        <template v-slot:row-details>
                            <sample-info v-bind:inItem="localItem">
                            </sample-info>
                        </template>
                    </b-table>
                    <div v-if="displayList.length == 0">
                        {{ strNoData }}
                    </div>
                </b-col>
            </b-row>
        </div>
        <modal-sample
            v-if="showDetailSample"
            v-model="showDetailSample"
            v-bind:inItem="sample"
            v-bind:isNew="isNew"
            @forcerefresh="doRefresh"
            @hide="reset"
        />
        <scan-barcode
            v-if="showScanner"
            v-model="showScanner"
            @result="showSampleDetail"
        />
    </b-container>
</template>

<script>
import PeriodSelector from '../components/PeriodSelector.vue';
import ScanBarcode from '../components/ScanBarcode.vue';
import ModalSample from './ModalSample.vue';
import SampleInfo from './SampleInfo.vue';
import DateHelper from '../../models/DateHelper';
import { Permission } from '../../models/Permissions';
import { loadItem } from '@/models/DataHelper';
import Helper from '../../models/Helper';

export default {
    name: 'SamplesBase',
    data: function () {
        return {
            showScanner: false,
            showDetailSample: false,
            barcodeSample: '',
            sample: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            van: null,
            tot: null,
            localActivities: [],
            objectNaam: '',
            aangeraakt_teller: 1,
            selectDays: null,
            periodeDagen: 60,
            forcerefresh: false,
            slackDagen: 1,
            plan: false,
            isNew: true,
            localItem: null
        };
    },
    components: {
        PeriodSelector,
        ScanBarcode,
        ModalSample,
        SampleInfo
    },
    computed: {
        strNoData() {
            return Helper.stringformat(
                this.$t('prod_nomaintenancedata', {
                    ns: 'production'
                }),
                this.van,
                this.tot
            );
        },
        title() {
            return this.plan ? 'Monsters: Gepland' : 'Monsters: Genomen';
        },
        btnTekst() {
            return this.plan ? 'Toon genomen' : 'Toon gepland';
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'plantijdstip',
                    label: this.$t('an_plandatum', { ns: 'analytics' }),
                    sortable: true,
                    visible: true,
                    formatter: 'displayDate'
                },
                {
                    key: 'neemtijdstip',
                    label: this.$t('an_sampledatum', { ns: 'analytics' }),
                    sortable: true,
                    visible: !this.plan,
                    formatter: 'displayDate'
                },
                {
                    key: 'barcode',
                    label: this.$t('com_barcode', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'meetplekNaam',
                    label: this.$t('an_meetplaats', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'naam',
                    label: this.$t('an_omschrijving', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'monsterTypeTekst',
                    label: this.$t('prod_type', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'eenheidBeschrijving',
                    label: this.$t('com_unit', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            const lijstA = this.$store.getters['analytics/getMonsterLijst'];
            const lijst = this.plan
                ? lijstA.filter((x) => x.neemtijdstip == undefined)
                : lijstA.filter((x) => x.neemtijdstip != undefined);
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = lijst ? lijst.length : 0;
            return lijst;
        },
        allowEdit() {
            // todo: wat worden de regels hiervoor?
            return true;
        },
        showPlan() {
            return this.plan;
        }
    },
    methods: {
        doRefresh() {
            this.forcerefresh = false;
            this.loadData();
        },
        async infoItem(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.displayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });
                this.sample = null;
                this.sample = await loadItem(
                    'analytics/loadSample',
                    { sampleID: row.id },
                    this.$t
                );
                this.localItem = this.sample;
                this.$set(row, '_showDetails', true);
            }
        },
        flipPlan() {
            this.plan = !this.plan;
            this.loadData();
        },
        scan() {
            this.showScanner = !this.showScanner;
        },
        reset() {
            this.showDetailSample = false;
        },
        displayDate(value) {
            return DateHelper.convertedToDate(value, true);
        },
        async showSampleDetail(value) {
            if (value == undefined) return;
            this.barcodeSample = value;
            const lijst = this.$store.getters['analytics/getMonsterLijst'];
            const tempSample = lijst.find(
                (x) => x.barcode == this.barcodeSample
            );
            if (tempSample) {
                this.bewerkItem(tempSample);
            } else {
                const bericht =
                    this.$t('com_nodata', { ns: 'common' }) +
                    '? ' +
                    this.$t('com_xnotfound', {
                        ns: 'common',
                        val: this.$t('com_barcode', { ns: 'common' })
                    }) +
                    ': ' +
                    this.barcodeSample;
                this.$root.showMessageBox(bericht);
            }
        },
        async bewerkItem(row) {
            this.sample = null;
            this.isNew = false;
            if (row) {
                this.sample = await loadItem(
                    'analytics/loadSample',
                    { sampleID: row.id },
                    this.$t
                );
            }
            this.showDetailSample = !this.showDetailSample;
        },
        async createNewItem(row) {
            this.sample = null;
            this.isNew = true;
            if (row) {
                this.sample = await loadItem(
                    'analytics/loadSample',
                    { sampleID: row.id },
                    this.$t
                );
            }
            this.showDetailSample = !this.showDetailSample;
        },
        async verwijderItem(row) {
            const bericht =
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('an_sample', { ns: 'analytics' })
                }) + row.naam;
            const doClose = await this.$root.showConfirmationBox(bericht);
            if (doClose) {
                await loadItem(
                    'analytics/deleteSample',
                    {
                        sampleID: row.id
                    },
                    this.$t
                );
            }
            this.loadData();
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            this.loadData();
        },
        loadData() {
            const selectionData = {
                from: DateHelper.localDateToUTC(this.van),
                until: DateHelper.localDateToUTC(this.tot)
            };
            loadItem('analytics/loadSampleLijst', selectionData, this.$t);
            this.aangeraakt_teller += 1;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted: function () {
        this.loadData();
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    created() {
        // laad de activiteiten en types als al niet gedaan
        loadItem('analytics/loadSampleTypeLijst', null, this.$t);
    }
};
</script>

<style scoped></style>

