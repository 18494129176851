import { render, staticRenderFns } from "./InpekelItemCombo.vue?vue&type=template&id=a8db2354&scoped=true"
import script from "./InpekelItemCombo.vue?vue&type=script&lang=js"
export * from "./InpekelItemCombo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8db2354",
  null
  
)

export default component.exports