var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"bs-example",attrs:{"fluid":""}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_vm._v("   "),_c('b-table',{ref:"selectableTable",attrs:{"striped":"","responsive":"","fields":_vm.visibleFields,"items":_vm.sortedList,"multi":"","selectable":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [(!_vm.isVersion10)?_c('b-button',{staticClass:"bgc_aux_warning",attrs:{"size":"sm"},on:{"click":function($event){return _vm.editItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit","size":"1x"}})],1):_vm._e(),(
                        !_vm.gesloten &&
                        _vm.isNotCheese(row.item) &&
                        _vm.checkRight('StockDelete') &&
                        !_vm.isVersion10
                    )?_c('b-button',{staticClass:"bgc_aux_alarm",attrs:{"disabled":_vm.inGebruik(row.item),"size":"sm"},on:{"click":function($event){return _vm.verwijderItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt","size":"1x"}})],1):_vm._e(),(!_vm.gesloten)?_c('b-button',{staticClass:"bgc_aux_unimportant",attrs:{"size":"sm"},on:{"click":function($event){return _vm.printLabel(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"print","size":"1x"}})],1):_vm._e(),(!_vm.gesloten)?_c('b-dropdown',{ref:"dropdown",attrs:{"id":"dropdown-form","text":"...","size":"sm","boundary":"viewport"},on:{"show":function($event){return _vm.setStartValues(row.item)}}},[_c('div',{staticClass:"fluid-container"},[_c('b-dropdown-form',[(
                                    !_vm.erisselected &&
                                    _vm.checkRight('StockOut') 
                                )?_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('log_verkoop', {
                                            ns: 'logistics'
                                        }),"label-for":"dropdown-form-correct"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('b-form-input',{attrs:{"id":"dropdown-form-correct","placeholder":_vm.$t('log_verkoopaantal', {
                                                ns: 'logistics'
                                            })},model:{value:(_vm.verkoopAmount),callback:function ($$v) {_vm.verkoopAmount=_vm._n($$v)},expression:"verkoopAmount"}}),_c('b-form-input',{attrs:{"placeholder":_vm.$t('log_verkoopref', {
                                                ns: 'logistics'
                                            })},model:{value:(_vm.verkoopID),callback:function ($$v) {_vm.verkoopID=$$v},expression:"verkoopID"}}),_c('lookup-combo',{attrs:{"combotype":_vm.lctypeCompany},model:{value:(_vm.klantID),callback:function ($$v) {_vm.klantID=$$v},expression:"klantID"}}),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveVerkoop(row.item)}}},[_vm._v("Ok")])],1)],1):_vm._e(),_c('b-dropdown-divider')],1),_c('b-dropdown-form',[(
                                    !_vm.erisselected && _vm.checkRight('StockMove')
                                )?_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('log_verplaats', {
                                            ns: 'logistics'
                                        }),"label-for":"dropdown-form-move"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('div',{staticClass:"form-inline"},[_c('b-form-input',{attrs:{"id":"dropdown-form-correct","placeholder":_vm.$t('log_correctieaantal', {
                                                    ns: 'logistics'
                                                })},model:{value:(_vm.verplaatsAmount),callback:function ($$v) {_vm.verplaatsAmount=_vm._n($$v)},expression:"verplaatsAmount"}}),_c('lookup-combo',{attrs:{"id":"dropdown-form-move","combotype":_vm.lctypeLocation},model:{value:(_vm.newlocationID),callback:function ($$v) {_vm.newlocationID=$$v},expression:"newlocationID"}})],1),_c('b-form-input',{attrs:{"placeholder":_vm.$t('log_externalref', {
                                                ns: 'logistics'
                                            })},model:{value:(_vm.verplaatsExternalID),callback:function ($$v) {_vm.verplaatsExternalID=$$v},expression:"verplaatsExternalID"}}),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveLocation(row.item)}}},[_vm._v("Ok")])],1)],1):_vm._e(),_c('b-dropdown-divider')],1),_c('b-dropdown-form',[(
                                    !_vm.erisselected &&
                                    _vm.checkRight('StockCorrect')
                                )?_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('log_corrigeer', {
                                            ns: 'logistics'
                                        }),"label-for":"dropdown-form-correct"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('b-form-input',{attrs:{"id":"dropdown-form-correct","placeholder":_vm.$t('com_newx', {
                                                ns: 'common',
                                                val: _vm.$t('com_number', {
                                                    ns: 'common'
                                                })
                                            })},model:{value:(_vm.newAmount),callback:function ($$v) {_vm.newAmount=_vm._n($$v)},expression:"newAmount"}}),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveAmount(row.item)}}},[_vm._v("Ok")])],1)],1):_vm._e(),_c('b-dropdown-divider'),(_vm.checkRight('StockClose'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.saveStockZero(row.item)}}},[_vm._v(" "+_vm._s(_vm.$t('log_closenostock', { ns: 'logistics' })))]):_vm._e(),(_vm.checkRight('StockClose'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.saveStockClose(row.item)}}},[_vm._v(_vm._s(_vm.$t('log_closenouse', { ns: 'logistics' })))]):_vm._e()],1)],1)]):_vm._e()]}},{key:"row-details",fn:function(){return [_c('print-prod-label',{attrs:{"visible":_vm.printVisible,"stockItemID":_vm.stockitemToPrint,"landscape":_vm.landscape}})]},proxy:true},{key:"cell(age)",fn:function({ item }){return [_c('span',{class:{
                        'text-green': item.age >= item.id,
                        'text-red': item.age < item.id
                    }},[_vm._v(" "+_vm._s(item.age)+" ")])]}}])}),_c('p',[(_vm.erisselected)?_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.clearSelected}},[_vm._v(_vm._s(_vm.$t('com_wisselectie', { ns: 'common' })))]):_vm._e()],1)],1),(_vm.detailsVisible)?_c('item-stock-modal',{attrs:{"inItem":_vm.currentItem,"tienplus":_vm.tienplus,"editAllowed":_vm.editAllowed},on:{"hide":_vm.reset},model:{value:(_vm.detailsVisible),callback:function ($$v) {_vm.detailsVisible=$$v},expression:"detailsVisible"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }