<template>
    <b-modal
        id="modal-brineingredients"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
        @change="change"
    >
        <b-card @submit.stop.prevent="handleSubmit" :header="strFormTitle">
            <div>
                <b-alert
                    v-model="showDismissibleAlert"
                    variant="danger"
                    dismissible
                >
                    {{ alertText }}
                </b-alert>

                <b-row>
                    <b-col sm="6">
                        <b-input-group
                            :prepend="$t('com_location', { ns: 'common' })"
                            :label="$t('com_location', { ns: 'common' })"
                        >
                            <lookup-combo
                                v-model="locationID"
                                v-bind:combotype="lctypeLocation"
                                v-bind:discrim="pekelbad"
                                @change="setChanged"
                            ></lookup-combo>
                        </b-input-group>
                    </b-col>
                    <b-col sm="3">
                        <b-input-group v-if="isVersion30 && locationID > 0">
                            <b-form-select
                                v-model="selectedIQ"
                                :options="flexList"
                                @input="setEx"
                            />
                            <b-button
                                @click="showMaesurementInsert()"
                                class="float-right bgc_aux_warning"
                                ><font-awesome-icon
                                    icon="microscope"
                                    size="1x"
                                />
                            </b-button>
                        </b-input-group>
                    </b-col>
                    <b-col sm="3" />
                </b-row>
                <b-row>
                    <b-col sm="9">
                        <stock-loc-ingredient-combo
                            v-model="locSiloIngredient"
                            @change="setChangedProduct"
                            @input="receiveSiloIngredient"
                            @nostock="removeButtons"
                        ></stock-loc-ingredient-combo>
                    </b-col>
                    <b-col sm="3">
                        <b-button
                            :disabled="!completeSiloIngredient"
                            @click="addSiloIngredient()"
                            class="bgc_aux_normal"
                            ><font-awesome-icon
                                icon="arrow-down"
                                size="1x"
                            />&nbsp;
                            {{
                                $t('com_new', {
                                    ns: 'common'
                                })
                            }}
                        </b-button>
                    </b-col>
                </b-row>
                <b-row
                    ><b-table
                        :items="siloIngredients"
                        :fields="visibleFields"
                        stacked="md"
                        show-empty
                        small
                        striped
                    >
                        <template v-slot:cell(actions)="row">
                            <div class="form-inline">
                                <b-button
                                    size="sm"
                                    @click="verwijderItem(row.item)"
                                    class="bgc_aux_alarm"
                                    ><font-awesome-icon
                                        icon="trash-alt"
                                        size="1x"
                                    />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-row>
                <b-row>
                    <b-col sm="9"> </b-col>
                    <b-col sm="3">
                        <b-button @click="saveThis()" class="bgc_aux_normal"
                            ><font-awesome-icon
                                icon="arrow-down"
                                size="1x"
                            />&nbsp;{{ $t('com_register', { ns: 'common' }) }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card
            @submit.stop.prevent="handleSubmit"
            :header="$t('com_historie', { ns: 'common' })"
        >
            <b-tabs card>
                <b-tab
                    :title="$t('prod_toegevoegd', { ns: 'production' })"
                    v-bind:key="1"
                    active
                >
                    <b-card-text>
                        <b-table
                            :items="groupedDisplayList"
                            :fields="visibleTTFields"
                            stacked="md"
                            show-empty
                            :empty-text="$t('com_geen_data', { ns: 'common' })"
                            small
                            striped
                            @row-clicked="toonVoorraad"
                        >
                            <template v-slot:row-details>
                                <brine-ingredients
                                    v-bind:addedIngredients="addedIngredients"
                                />
                            </template>
                        </b-table>
                    </b-card-text>
                </b-tab>
                <b-tab @click="loadActivities()" lazy>
                    <template #title>
                        <b-spinner
                            v-if="showSpinner"
                            type="border"
                            small
                        ></b-spinner>
                        {{ $t('com_activitylog', { ns: 'common' }) }}
                    </template>
                    <b-card-text>
                        <b-table
                            striped
                            responsive
                            :fields="fieldsActiviteiten"
                            :items="activityList"
                            primary-key
                        >
                        </b-table>
                    </b-card-text>
                </b-tab>
            </b-tabs>
            <modal-measurement
                v-if="showDetailsMeas"
                v-model="showDetailsMeas"
                v-bind:isNew="true"
                v-bind:idIQ="selectedIQ"
                :idEntityObject="idEO"
                :idExecutor="idEx"
                @hide="reset"
            />
        </b-card>
    </b-modal>
</template>

<script>
import { SiloIngredient, SiloIngredients } from '@/models/BakInfo_helper';
import DateHelper from '@/models/DateHelper';
import { loadItem, loadList } from '../../models/DataHelper';
import { LookupCombo, StockLocIngredientCombo } from '../components';
import ModalMeasurement from '../analytics/ModalMeasurement.vue';
import BrineIngredients from './BrineIngredients.vue';

export default {
    name: 'BrineIngredientsModal',
    data: function () {
        return {
            lctypeLocation: window.constants.LCLOCATION,
            pekelbad: window.constants.BRINESTOCKLOCATION.toString(), // moet string zijn, 5 is pekelbad
            selectedIQ: -1,
            idEO: -1,
            idEx: -1,
            flexList: [],
            locationID: -1,
            showDetailsMeas: false,
            showDismissibleAlert: false,
            alertText: '',
            completeSiloIngredient: true,
            locSiloIngredient: new SiloIngredient(),
            locSiloIngredients: new SiloIngredients(),
            siloIngredients: [],
            perUnitsId: 0,
            melkId: 0,
            localNaam: '',
            opmerking: '',
            productID: null,
            vetKlasseEdit: false,
            nostock: false,
            merkaantal: 0,
            aangeraakt_teller: 0,
            gebruikList: [],
            addedIngredientsList: [],
            showSpinner: false,
            fieldsActiviteiten: [
                {
                    key: 'gemaakt',
                    label: this.$t('prod_gemaakt', { ns: 'production' })
                },
                {
                    key: 'actie',
                    label: this.$t('prod_activity', { ns: 'production' })
                },
                {
                    key: 'persoon',
                    label: this.$t('prod_loggedin', { ns: 'production' })
                }
            ],
            activityList: [],
            strFormTitle: this.$t('prod_addproductpekel', { ns: 'production' }),
            strTitle: this.$t('prod_addpekel', { ns: 'production' })
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        baseDate: []
    },
    components: {
        StockLocIngredientCombo,
        LookupCombo,
        BrineIngredients,
        ModalMeasurement
    },
    watch: {},
    computed: {
        isVersion30() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] >=
                window.constants.VERSION30
            );
        },
        formtitle() {
            return this.strTitle;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'plaats',
                    label: this.$t('com_plaats', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'productNaam',
                    label: this.$t('com_product', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'partijNummer',
                    label: this.$t('com_batch', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'hoeveelheid',
                    label: this.$t('com_amount', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'eenheid',
                    label: this.$t('com_unit', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        groupedDisplayList() {
            if (this.gebruikList == undefined) return null;
            const x = _(this.gebruikList.slice(0))
                .groupBy((x) => x.plaats)
                .map((value, key) => {
                    const naam = value[0].plaats;
                    return {
                        naam: naam,
                        siloingredients: value
                    };
                })
                .value();
            const y = _.sortBy(x, ['naam']);
            return y;
        },
        addedIngredients() {
            return this.addedIngredientsList;
        },
        visibleTTFields() {
            const fieldsInit = [
                {
                    key: 'naam',
                    label: this.$t('com_location', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'siloingredients.length',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        reset() {
            this.showDetailsMeas = false;
        },
        async showMaesurementInsert() {
            if (this.isVersion30) {
                const o = await loadItem(
                    'analytics/loadEntityObjectByKeyType',
                    {
                        key: this.locationID,
                        typeName: window.constants.ADATA_STOCKLOCATION
                    },
                    this.$t
                );
                this.idEO = o ? o.id : -1;
                this.showDetailsMeas = !this.showDetailsMeas;
            }
        },
        removeButtons(value) {
            this.nostock = value;
            this.aangeraakt += 1;
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        toonVoorraad(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.groupedDisplayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });

                const item = row.item ? row.item : row;
                this.addedIngredientsList = item.siloingredients;
                this.$set(row, '_showDetails', true);
            }
        },
        addSiloIngredient() {
            if (this.locSiloIngredient.eenheid == undefined) {
                // work around
                const unit = this.$store.getters['components/getUnitbyID'](
                    this.locSiloIngredient.eenheidID
                );
                this.locSiloIngredient.eenheid = unit.text;
            }

            this.locSiloIngredient.datum =
                this.locSiloIngredient.datum ?? this.baseDate ?? new Date();

            this.locSiloIngredient.plaatsID = this.locationID;
            const lijst =
                this.$store.getters['components/getLocationsComboLijst'];
            const loc = lijst.find((x) => x.value == this.locationID);
            this.locSiloIngredient.plaats = loc?.text;
            this.locSiloIngredients.SiloIngredients.push(
                this.locSiloIngredient
            );
            this.locSiloIngredient = new SiloIngredient();
            this.siloIngredients = this.locSiloIngredients.SiloIngredients;
        },
        verwijderItem(item) {
            const test = this.locSiloIngredients.SiloIngredients.indexOf(item);

            if (test > -1) {
                this.locSiloIngredients.SiloIngredients.splice(test, 1);
                this.siloIngredients = this.locSiloIngredients.SiloIngredients;
            }
        },
        change(visible) {
            this.setChanged();
            this.$emit('change', visible);
        },
        setChanged() {
            this.isChanged = true;
        },
        async setChangedProduct() {
            this.$nextTick(() => {
                const product = this.$store.getters[
                    'components/getProductsbyID'
                ](this.productID);
            });
            this.setChanged();
        },
        receiveSiloIngredient(siloIngredient) {
            this.completeSiloIngredient =
                siloIngredient.productID > 0 &&
                siloIngredient.hoeveelheid > 0 &&
                siloIngredient.eenheidID > 0 &&
                siloIngredient.partijID > 0;
            this.locSiloIngredient = siloIngredient;
        },
        checkFormValidity() {
            const invalidBasis = this.locSiloIngredients.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid = !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            this.saveThis();
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-brineingredients');
            });
        },
        async saveThis() {
            if (this.locSiloIngredients.SiloIngredients.length > 0) {
                await loadItem(
                    'outbrine/addStockLocIngredients',
                    this.locSiloIngredients.SetForAdd(),
                    this.$t
                );
                this.locSiloIngredients = new SiloIngredients();
                this.loadSiloIngredients();
            }
        },
        async loadActivities() {
            this.showSpinner = true;
            if (this.locationID > -1) {
                this.activityList = await loadItem(
                    'outbrine/loadStockLocationActiviteitenLijst',
                    {
                        stockLocationID: this.locationID
                    },
                    this.$t
                );
            }
            this.showSpinner = false;
        },
        async loadSiloIngredients() {
            const vantot = DateHelper.determineVanTot(this.baseDate, 30);
            const selectionData = {
                from: DateHelper.localDateToUTC(vantot.van),
                until: DateHelper.localDateToUTC(vantot.tot)
            };
            this.gebruikList = await loadItem(
                'outbrine/loadStockLocationIngredientInfo',
                selectionData,
                this.$t
            );
        },
        async loadIQs() {
            const lijst = await loadList(
                'analytics/getTemplateLijst',
                'analytics/loadTemplateLijst',
                null,
                false,
                this.$t
            );
            const a = lijst.filter((c) => {
                return c.templateid == window.constants.ADATA_TE_PEKELBAD;
            });
            this.flexList = a.map((x) => ({
                value: x.id,
                text: x.naam,
                uitvoerderid: x.uitvoerderid
            }));
            if (this.flexList.length > 0) {
                this.selectedIQ = this.flexList[0].id;
                this.idEx = this.flexList[0].uitvoerderid;
            }
        },
        setEx(val) {
            if (val > 0) {
                const o = this.flexList.find((x) => (x.value == val));   
                this.idEx = o?.uitvoerderid;
            }
        }
    },
    created() {
        this.loadSiloIngredients();
        this.loadIQs();
    }
};
</script>

<style scoped></style>

