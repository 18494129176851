import { api } from '../api';
import { showBadToast, showGoodToast } from '../../errors/toast';
import logMessage from '../../errors/foutlog';
import {
    Onderhoud,
    OnderhoudsType,
    OnderhoudsActivity
} from '../../../models/Onderhoud';
const actions = {
    // async loadMaintenance(context, data) {
    //     let item = null;
    //     try {
    //         await api.fetchMaintenance(data).then((response) => {
    //             item = new Onderhoud(response.data);
    //         });
    //     } catch (error) {
    //         showBadToast(context, 'Melding laden reiniging ', error);
    //         logMessage(error, 'error');
    //     }
    //     context.commit('insertSelectedMaintenance', item);
    //     return item;
    // },
    async deleteMaintenance(context, data) {
        try {
            await api.deleteMaintenance(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('prod_reinigingsitem', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('prod_reinigingsitem', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async loadMaintenanceInfoOverPeriod(context, data) {
        let tempList = [];
        try {
            await api.fetchMaintenanceInfoOverPeriod(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new Onderhoud(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('prod_reinigingsinfo', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertMaintenanceInfoLijst', tempList);
    },
    async addMaintenance(context, data) {
        try {
            await api.addMaintenance(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_reiniging', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_reiniging', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },

    async loadMaintenanceActivities(context, data) {
        let tempList = [];
        try {
            await api.fetchMaintenanceActivities(data.data).then((response) => {
                tempList = response.data.map((x) => new OnderhoudsActivity(x, data.func));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_activities', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertOnderhoudsActivitiesLijst', tempList);
    },
    async loadMaintenaceTypes(context, data) {
        let tempList = [];
        try {
            await api.fetchMaintenaceTypes(data.data).then((response) => {
                tempList = response.data.map((x) => new OnderhoudsType(x, data.func));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_type', { ns: 'production' }).toLowerCase()
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertOnderhoudsTypesLijst', tempList);
    },
};

export default actions;
