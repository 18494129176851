<template>
    <b-card @submit.stop.prevent="handleSubmit" no-body>
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <b-tabs card>
            <b-tab title="Normen">
                <b-card-text>
                    <div>
                        <b-table
                            striped
                            responsive
                            :fields="detailNormFields"
                            :items="localNormList"
                            stacked="md"
                            show-empty
                            :empty-text="$t('com_geen_data', { ns: 'common' })"
                            small
                        >
                            <template v-slot:cell(actions)="row">
                                <div class="form-inline">
                                    <!--                                   <b-button
                                        v-if="checkRight('AnalyticsConfigure')"
                                        size="sm"
                                        @click="bewerkItem(row.item)"
                                        class="bgc_aux_warning"
                                        ><font-awesome-icon
                                            icon="edit"
                                            size="1x"
                                        />
                                    </b-button> -->
                                    <b-button
                                        v-if="checkRight('AnalyticsConfigure')"
                                        size="sm"
                                        @click="verwijderItem(row.item)"
                                        class="bgc_aux_alarm"
                                        ><font-awesome-icon
                                            icon="trash-alt"
                                            size="1x"
                                        />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </b-card-text>
            </b-tab>
            <b-tab @click="loadActData()" lazy>
                <template #title>
                    <b-spinner
                        v-if="showSpinner"
                        type="border"
                        small
                    ></b-spinner>
                    {{ $t('com_activitylog', { ns: 'common' }) }}
                </template>
                <b-card-text>
                    <b-table
                        striped
                        responsive
                        :fields="fieldsActiviteiten"
                        :items="activiteitenLijst"
                        primary-key
                    >
                    </b-table>
                </b-card-text>
            </b-tab>
        </b-tabs>
        <modal-norm
            v-if="detailsVisible"
            v-model="detailsVisible"
            v-bind:inItem="selectednorm"
            v-bind:idIQ="detailID"
            @hide="reset"
        />
    </b-card>
</template>

<script>
import { Permission } from '../../models/Permissions';
import Helper from '../../models/Helper';
import { loadItem } from './utils';
import ModalNorm from './ModalNorm.vue';
export default {
    name: 'InvestQuantityCard',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            showSpinner: false,
            fieldsActiviteiten: [
                {
                    key: 'gemaakt',
                    label: this.$t('prod_gemaakt', { ns: 'production' })
                },
                {
                    key: 'actie',
                    label: this.$t('prod_activity', { ns: 'production' })
                },
                {
                    key: 'persoon',
                    label: this.$t('prod_loggedin', { ns: 'production' })
                }
            ],
            activityList: [],
            detailsVisible: false,
            selectednorm: null,
            verwijderString: this.$t('com_delete', {
                ns: 'common',
                val: Helper.stringformat(
                    '{0} {1} {2}',
                    this.$t('an_norm', { ns: 'analytics' }).toLowerCase(),
                    this.$t('com_voor', { ns: 'common' }).toLowerCase(),
                    this.$t('an_meetplaats', { ns: 'analytics' }).toLowerCase()
                )
            })
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        normList: {
            type: Array
        },
        detailID: {
            type: Number
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ModalNorm
    },
    watch: {},
    computed: {
        detailNormFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                {
                    key: 'meetplekNaam',
                    label: this.$t('an_meetplaats', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'normwaarde',
                    label: this.$t('an_norm', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'eenheidNaam',
                    label: this.$t('com_unit', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'formuleDisplay',
                    label: this.$t('an_grenzen', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        localNormList() {
            return this.normList;
        },
        activiteitenLijst() {
            return this.activityList;
        }
    },
    methods: {
        reset() {
            this.detailsVisible = false;
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        async loadActData() {
            this.showSpinner = true;
            this.activityList = await loadItem(
                'analytics/loadIQActiviteitenLijst',
                {
                    investigationquantityID: this.detailID
                },
                this.$t
            );
            this.showSpinner = false;
        },
        async bewerkItem(row) {
            this.selectednorm = await loadItem(
                'analytics/loadNorm',
                {
                    entityObjectID: row.meetplekID,
                    invQuantityID: row.onderzoeksgrootheidID
                },
                this.$t
            );
            this.detailsVisible = !this.detailsVisible;
        },
        async verwijderItem(row) {
            const bericht = Helper.stringformat(
                '{0}: {1}',
                this.verwijderString,
                row.meetplekNaam
            );
            const doClose = await this.$root.showConfirmationBox(bericht);
            if (doClose) {
                await loadItem(
                    'analytics/deleteNorm',
                    {
                        eonormID: row.id
                    },
                    this.$t
                );
            }
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted() {},
    created() {}
};
</script>

<style scoped></style>

