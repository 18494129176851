<template>
    <div class="form-inline">
        <b-input-group>
            <b-form-select
                v-model="selectedType"
                :options="eotypes"
                :disabled="!editAllowed"
                @change="set_inputType"
            >
                <template #first>
                    <b-form-select-option value=""
                        >--
                        {{ $t('com_selecteer', { ns: 'common' }) }}
                        --</b-form-select-option
                    >
                </template>
            </b-form-select>
            <div v-if="batchrelated" class="form-inline">
                <b-form-datepicker
                    :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit'
                    }"
                    v-model="datum"
                    @input="set_inputDate"
                    :disabled="!editAllowed"
                ></b-form-datepicker>
                <b-form-select
                    v-model="meetplekIDBatch"
                    :options="cheeseRelatedLijst"
                    :disabled="!editAllowed"
                    @change="set_input"
                >
                    <template #first>
                        <b-form-select-option value=""
                            >--
                            {{ $t('com_selecteer', { ns: 'common' }) }}
                            --</b-form-select-option
                        >
                    </template>
                </b-form-select>
            </div>
            <div v-if="eorelated">
                <lookup-combo
                    key="rerenderkey"
                    v-model="meetplekID"
                    v-bind:combotype="lctypeEO"
                    v-bind:discrim="theDiscrim"
                    v-bind:forcerefresh="forcerefresh"
                    @change="set_input"
                    :disabled="!editAllowed"
                ></lookup-combo>
            </div>
        </b-input-group>
    </div>
</template>
<script>
import LookupCombo from './LookupCombo.vue';
import DateHelper from '../../models/DateHelper';
import { loadList, loadItem } from './utils';
export default {
    name: 'EntityObjectSelector',
    data: function () {
        return {
            eotypes: [
                {
                    value: window.constants.ADATA_BATCH,
                    text: this.$t('prod_bak', { ns: 'production' })
                },
                {
                    value: window.constants.ADATA_BATCHGROUP,
                    text: this.$t('prod_bakgroep', { ns: 'production' })
                },
                {
                    value: window.constants.ADATA_STOCKLOCATION,
                    text: this.$t('com_location', { ns: 'common' })
                },
                {
                    value: window.constants.ADATA_EQUIPMENT,
                    text: this.$t('prod_apparaat', { ns: 'production' })
                }
            ],
            selectedType: window.constants.ADATA_BATCH,
            lctypeEO: window.constants.LCA_ENTITYOBJECT,
            flexList: [],
            aangeraakt: 1,
            rerenderkey: 1,
            startEntityObjectID: -1,
            meetplekIDBatch: null,
            meetplekID: null,
            batchrelated: false,
            eorelated: false,
            forcerefresh: true
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: Number,
            default: -1
        },
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        selected(newVal) {
            this.setSelected(newVal);
        }
    },
    components: {
        LookupCombo
    },
    computed: {
        cheeseRelatedLijst() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            return this.flexList;
        },
        theDiscrim() {
            return this.selectedType;
        }
    },
    methods: {
        set_input() {
            this.$nextTick(async () => {
                const id = this.batchrelated
                    ? this.meetplekIDBatch
                    : this.meetplekID;
                this.$emit('change', true);
                this.$emit('input', id);
            });
        },
        async set_inputDate() {
            this.$nextTick(async () => {
                const templijst = await loadItem(
                    'analytics/loadEntityObjectForCheese',
                    { productiondate: this.datum },
                    this.$t
                );
                const filteredLijst = templijst
                    ? templijst.filter(
                          (x) => x.typenaambasis == this.selectedType
                      )
                    : [];
                this.flexList = filteredLijst.map((x) => ({
                    value: x.id,
                    text: x.naam,
                    item: x
                }));
                this.aangeraakt += 1;
            });
        },
        set_inputType() {
            this.$nextTick(async () => {
                this.batchrelated = false;
                this.eorelated = false;
                switch (this.selectedType) {
                    case window.constants.ADATA_BATCHGROUP:
                    case window.constants.ADATA_BATCH: {
                        this.batchrelated = true;
                        this.set_inputDate();
                        break;
                    }
                    default: {
                        this.eorelated = true;
                        break;
                    }
                }
                this.rerenderkey += 1;
            });
        },
        addCustomTypesToList(lijst) {
            for (let i = 0; i < lijst.length; i++) {
                const str = lijst[i];
                const obj = { value: str, text: str };
                this.eotypes.push(obj);
            }
        },
        async setSelected(val) {
            this.startEntityObjectID = val;
            const startObject = await loadItem(
                'analytics/loadEntityObject',
                { entityObjectID: this.startEntityObjectID },
                this.$t
            );
            this.selectedType = startObject.typenaambasis;
            this.datum = DateHelper.convertedToDateStringFromUTC(
                startObject.referentieDatum
            );
            const isCheese =
                this.selectedType == window.constants.ADATA_BATCHGROUP ||
                this.selectedType == window.constants.ADATA_BATCH;
            if (isCheese) {
                this.batchrelated = true;
                this.eorelated = false;
                this.meetplekIDBatch = this.startEntityObjectID;
                this.set_inputDate();
            } else {
                this.batchrelated = false;
                this.eorelated = true;
                this.meetplekID = this.startEntityObjectID;
            }
        }
    },
    mounted() {
        if (this.selected > -1) {
            this.setSelected(this.selected);
        } else {
            this.startEntityObjectID = -1;
            const tijd = DateHelper.MakeDateTime(new Date());
            this.datum = DateHelper.convertedToDateStringFromUTC(tijd);
        }
        this.set_inputType();
    },
    async created() {
        const tijd = DateHelper.MakeDateTime(new Date());
        this.datum = DateHelper.convertedToDateStringFromUTC(tijd);
        const lijst = await loadList(
            'analytics/getEOTypeNamesLijst',
            'analytics/loadUserDefinedTypeNames',
            null,
            this.forcerefresh,
            this.$t
        );
        this.addCustomTypesToList(lijst);
    }
};
</script>

<style scoped></style>

