<template>
    <b-container fluid style="margin-top: 60px">
        <h1 v-on:click="getData">
            {{ strProdValueTitle }}
            <b-button
                @click="getData()"
                class="bgc_aux_normal"
                v-b-tooltip.hover
                ><font-awesome-icon icon="arrow-down" size="1x" />
            </b-button>
        </h1>

        <b-row>
            <b-col sm="12">
                <b-row no-gutters>
                    <b-col lg="6" class="my-1">
                        <b-form-group
                            :label="$t('com_filter', { ns: 'common' })"
                            label-for="filter-input"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <b-input-group size="sm">
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    :placeholder="
                                        $t('com_typetosearch', { ns: 'common' })
                                    "
                                ></b-form-input>

                                <b-input-group-append>
                                    <b-button
                                        :disabled="!filter"
                                        @click="filter = ''"
                                        >{{ $t('com_wis', { ns: 'common' }) }}</b-button
                                    >
                                </b-input-group-append>
                            </b-input-group>

                            <b-button
                                size="sm"
                                @click="createNewItem"
                                class="mr-1"
                                ><font-awesome-icon
                                    icon="folder-plus"
                                    size="1x"
                                />
                                {{ strNewProdval }}
                            </b-button>
                        </b-form-group>
                    </b-col>

                    <b-col lg="6" class="my-1">
                        <b-form-group
                            :label="$t('com_perpagina', { ns: 'common' })"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                            ></b-pagination>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-table
                    :items="displayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                >
                    <template v-slot:cell(actions)="row">
                        <b-button
                            size="sm"
                            @click="editItem(row.item)"
                            class="bgc_aux_warning"
                            ><font-awesome-icon icon="edit" size="1x" />
                        </b-button>
                        <b-button
                            size="sm"
                            @click="verwijderItem(row.item)"
                            class="bgc_aux_alarm"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                    </template>

                    <template v-slot:row-details> </template>
                </b-table>
            </b-col>
        </b-row>
        <plus-product-value-modal
            v-if="detailsVisible"
            :inItem="currentItem"
            v-model="detailsVisible"
            @hide="reset"
        />
    </b-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import { loadItem, loadList, loadGetItem } from '../../models/DataHelper';
import { Feature } from '../../models/Features';
import { Permission } from '../../models/Permissions';
import { SimpleProductionValue } from '../../models/PlusProduction';
import PlusProductValueModal from './PlusProductValueModal.vue';

export default defineComponent({
    name: 'PlusProductValueBase',
    data: function () {
        return {
            prodvaluelijst: [] as Array<SimpleProductionValue>,
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            currentItem: ({} as SimpleProductionValue) || null,
            detailsVisible: false,
            strProdValueTitle: this.$t('tien_title', {
                ns: 'tien',
                val: this.$t('com_prodvalue', { ns: 'common' })
            }),
            strNewProdval: this.$t('com_newx', {
                ns: 'common',
                val: this.$t('com_prodvalue', { ns: 'common' })
            }),
            strDeleteProdValue: this.$t('com_delete', {
                ns: 'common',
                val: this.$t('com_prodvalue', { ns: 'common' })
            })
        };
    },
    props: {},
    components: { PlusProductValueModal },
    computed: {
        displayList() {
            return this.prodvaluelijst;
        },
        useVersion10() {
            return this.checkFeature('conf_CH10Enabled');
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'naam',
                    label: this.$t('com_naam', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'opmerking',
                    label: this.$t('com_opmerking', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'elementcount',
                    label: this.$t('com_onderdelen', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        createNewItem() {
            this.currentItem = new SimpleProductionValue({});
            this.detailsVisible = true;
        },
        async editItem(item) {
            const selData = { productionValueID: item.id };
            this.currentItem = await loadItem(
                'tienplus/loadProductionValue',
                selData,
                this.$t
            );
            this.detailsVisible = true;
        },
        async verwijderItem(item) {
            const doClose = await (
                this.$root as VueExtension
            ).showConfirmationBox(
                this.strDeleteProdValue + item.id + ' : ' + item.naam
            );
            if (doClose) {
                const refreshedItem = await loadItem(
                    'tienplus/loadProductionValue',
                    {
                        productionValueID: item.id
                    },
                    this.$t
                );
                loadItem(
                    'tienplus/deleteProductionValue',
                    {
                        id: refreshedItem.id,
                        lastupdated: refreshedItem.gewijzigd
                    },
                    this.$t
                );
            }
        },
        reset(item: SimpleProductionValue) {
            if (item) {
                if (item.isChanged) this.getData();
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getData() {
            this.prodvaluelijst = await loadList(
                'tienplus/getProductValueLijst',
                'tienplus/loadProductionValues',
                null,
                true,
                this.$t
            );
            this.totalRows = this.prodvaluelijst
                ? this.prodvaluelijst.length
                : 0;
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return (this.$root as VueExtension).handleFeature(key);
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return (this.$root as VueExtension).handleRight(id);
        }
    },
    async mounted() {
        await this.getData();
    }
});
</script>

<style scoped></style>

