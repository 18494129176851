import Helper from './Helper';
import DateHelper from './DateHelper';
export { RECURRENCY_ENUM, recurrencyHelper, RecurrencyPattern };

const RECURRENCY_ENUM = Object.freeze({
    Daily: 1,
    Weekly: 2,
    Monthly: 3,
    Yearly: 4,
    Weekday: 5
});

class recurrencyHelper {
    static lijstWeken(func) {
        const arr = [
            { value: 1, text: func('rec_first', { ns: 'recurrency' }) },
            { value: 2, text: func('rec_second', { ns: 'recurrency' }) },
            { value: 3, text: func('rec_third', { ns: 'recurrency' }) },
            { value: 4, text: func('rec_forth', { ns: 'recurrency' }) },
            { value: 5, text: func('rec_last', { ns: 'recurrency' }) }
        ];
        return arr;
    }

    static lijstDagen(func) {
        const arr = [
            { value: 0, text: func('rec_zondag', { ns: 'recurrency' }) },
            { value: 1, text: func('rec_maandag', { ns: 'recurrency' }) },
            { value: 2, text: func('rec_dinsdag', { ns: 'recurrency' }) },
            { value: 3, text: func('rec_woensdag', { ns: 'recurrency' }) },
            { value: 4, text: func('rec_donderdag', { ns: 'recurrency' }) },
            { value: 5, text: func('rec_vrijdag', { ns: 'recurrency' }) },
            { value: 6, text: func('rec_zaterdag', { ns: 'recurrency' }) }
        ];
        return arr;
    }

    static lijstMaanden(func) {
        const arr = [
            { value: 1, text: func('rec_januari', { ns: 'recurrency' }) },
            { value: 2, text: func('rec_februari', { ns: 'recurrency' }) },
            { value: 3, text: func('rec_maart', { ns: 'recurrency' }) },
            { value: 4, text: func('rec_april', { ns: 'recurrency' }) },
            { value: 5, text: func('rec_mei', { ns: 'recurrency' }) },
            { value: 6, text: func('rec_juni', { ns: 'recurrency' }) },
            { value: 7, text: func('rec_juli', { ns: 'recurrency' }) },
            { value: 8, text: func('rec_augustus', { ns: 'recurrency' }) },
            { value: 9, text: func('rec_september', { ns: 'recurrency' }) },
            { value: 10, text: func('rec_oktober', { ns: 'recurrency' }) },
            { value: 11, text: func('rec_november', { ns: 'recurrency' }) },
            { value: 12, text: func('rec_december', { ns: 'recurrency' }) }
        ];
        return arr;
    }
}

/* rp_type 1: daily 2:weekly 3:monthly 4:yearly 5:weekday  */
/* rp_interval daily: an appointment occurs every interval days  */
/* 		weekly: an appointment occurs on days every interval weeks  */
/* 		monthly: an appointment occurs on day dayofmonth every interval months  */
/*  	yearly: an appointment occurs on day dayofmonth of month every interval years  */
/* rp_days dagen:  1;3;4  (opslaan met dagnummers zondag = 0)  */
/* rp_weeks weken: 2;4 (1 is eerste week van de maand )  */
/* rp_dayofweek dagen: 1;3;4 (opslaan met dagnummers zondag = 0)  */
/* rp_dayofmonth dagen: 15  */
/* rp_months maanden: 2;4 (1 is eerste maand van het jaar)  */
class RecurrencyPattern {
    constructor(data) {
        data = data ?? {};
        this.id = data.id ?? -1;
        this.changed = false;
        this.deleted = false;
        this.naam = data.description;
        this.type = data.type;
        this.displayType =
            data.type == RECURRENCY_ENUM.Weekday
                ? RECURRENCY_ENUM.Daily
                : data.type;
        this.pauze = data.interval;
        this.dagen = data.days ? data.days.split(';') : [];
        this.weken = data.weeks ? data.weeks.split(';') : [];
        this.maanden = data.months ? data.months.split(';') : [];
        this.weekdagen = data.dayOfWeek ? data.dayOfWeek.split(';') : [];
        this.maanddagen = data.dayOfMonth ? data.dayOfMonth.split(';') : [];
        // alleen voor weergave
        this.laatstekeer = data.lastOccurrence ;
        this.volgendekeer = data.nextOccurrence ;
    }

    CheckValidity(func) {
        let warnText = '';
        if (!this.naam && this.id > -1)
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_reference', { ns: 'common' })
                })
            );
        return warnText;
    }
    // dailydata :  interval,type,dagen
    SaveDaily(dailydata) {
        this.type = dailydata.type;
        if (dailydata.type == RECURRENCY_ENUM.Weekday) {
            this.dagen = dailydata.dagen;
        } else {
            this.pauze = dailydata.interval;
        }
        this.changed = true;
        return this.SetForSave();
    }
    // weeklydata :  interval,type, dagen
    SaveWeekly(weeklydata) {
        this.type = weeklydata.type;
        this.dagen = weeklydata.dagen;
        this.pauze = weeklydata.interval;
        this.changed = true;
        return this.SetForSave();
    }
    // monthlydata :  interval,type, dagen, weken, maanddagen
    SaveMonthly(monthlydata) {
        this.type = monthlydata.type;
        this.dagen = monthlydata.dagen;
        this.weken = monthlydata.weken;
        this.maanddagen = monthlydata.maanddagen;
        this.pauze = monthlydata.interval;
        this.changed = true;
        return this.SetForSave();
    }
    // yearlydata :  interval,type, dagen, weken, maanden, maanddagen
    SaveYearly(yearlydata) {
        this.type = yearlydata.type;
        this.dagen = yearlydata.dagen;
        this.weken = yearlydata.weken;
        this.maanden = yearlydata.maanden;
        this.maanddagen = yearlydata.maanddagen;
        this.pauze = yearlydata.interval;
        this.changed = true;
        return this.SetForSave();
    }
    SetForSave() {
        const RecPatternModel = {
            Id: this.id,
            Description: this.naam,
            Type: this.type,
            Interval: this.pauze,
            Days: this.dagen.join(';'),
            Weeks: this.weken.join(';'),
            Months: this.maanden.join(';'),
            DayOfWeek: this.weekdagen.join(';'),
            DayOfMonth: this.maanddagen.join(';'),
            Changed: this.changed,
            Deleted: this.deleted
        };
        return RecPatternModel;
    }
}
