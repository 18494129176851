<template>
    <b-modal
        id="modal-itemMaintenance"
        size="lg"
        ref="modal"
        :title="formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <b-card :header="$t('prod_activities', { ns: 'production' })">
            <b-row v-if="'ost' == displayNumber">
                <b-col sm="3">
                    <label>{{ ostLabel }}:</label>
                </b-col>
                <b-col sm="9">
                    <div class="form-inline">
                        <b-form-select
                            v-model="localOst"
                            :options="wrongelbereiderList"
                            :disabled="itemAdded"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >-- {{$t('com_selecteer', { ns: 'common' })}} --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="'loc' == displayNumber || 'silo' == displayNumber">
                <b-col sm="3">
                    <label>{{ locationLabel }}: </label>
                </b-col>
                <b-col sm="9">
                    <div class="form-inline">
                        <b-form-select
                            v-model="localLocation"
                            :options="locationList"
                            :disabled="itemAdded"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{
                                        $t('com_selecteer', {
                                            ns: 'common'
                                        })
                                    }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="'eq' == displayNumber">
                <b-col sm="3">
                    <label>{{ locationLabel }}: </label>
                </b-col>
                <b-col sm="9">
                    <div class="form-inline">
                        <b-form-select
                            v-model="localApparaat"
                            :options="apparatenList"
                            :disabled="itemAdded"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{
                                        $t('com_selecteer', {
                                            ns: 'common'
                                        })
                                    }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col sm="3">
                    <label>
                        {{ $t('prod_bepactietype', { ns: 'production' }) }}
                        :</label
                    >
                </b-col>
                <b-col sm="9">
                    <div class="form-inline">
                        <b-form-select
                            v-model="localType"
                            :options="typeList"
                            :disabled="itemAdded"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{
                                        $t('com_selecteer', {
                                            ns: 'common'
                                        })
                                    }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                        <b-button
                            v-if="!showActivities"
                            @click="addActivity('NoActivity')"
                            class="bgc_aux_normal"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="showActivities">
                <b-col sm="3">
                    <label>
                        {{
                            $t('prod_add', {
                                ns: 'production',
                                val: $t('prod_activity', {
                                    ns: 'production'
                                }).toLowerCase()
                            })
                        }}
                        :</label
                    >
                </b-col>
                <b-col sm="9">
                    <div class="form-inline">
                        <b-form-select
                            v-model="localActivity"
                            :options="activityList"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{
                                        $t('com_selecteer', {
                                            ns: 'common'
                                        })
                                    }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                        <b-button @click="addActivity()" class="bgc_aux_normal"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-form-textarea
                    v-model="activiteitOpmerking"
                    :placeholder="$t('com_opmerking', { ns: 'common' })"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
            </b-row>
        </b-card>
        <b-card :header="$t('prod_activities', { ns: 'production' })">
            <maintenance-items
                v-bind:maintenanceItemlist="getmaintenanceList"
            />
        </b-card>
    </b-modal>
</template>

<script>
import { Onderhoud } from '../../models/Onderhoud';
import Helper from '../../models/Helper';
import { loadItem, loadList } from '../../models/DataHelper';
import MaintenanceItems from './MaintenanceItems.vue';

export default {
    name: 'MaintenancePopForm',
    data() {
        return {
            showDismissibleAlert: false,
            alertText: '',
            readonly: false,
            isChanged: false,
            objectOnderhoud: null,
            activiteitOpmerking: '',
            localActivity: window.constants.BASE_MAINTENANCEACTIVITY,
            localType: window.constants.BASE_MAINTENANCETYPE,
            localOst: null,
            localApparaat: null,
            tijdstip: null,
            localLocation: null,
            maintenanceList: [],
            locationList: [],
            apparatenList: [],
            wrongelbereiderList: [],
            activiteitenLijst: [],
            typeLijst: []
        };
    },
    components: {
        MaintenanceItems
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        displayNumber: {
            type: String,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        },
        ostID: {
            type: Number,
            required: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        formtitle() {
            return Helper.stringformat(
                this.$t('prod_add', {
                    ns: 'production',
                    val: this.$t('prod_onderhoud', {
                        ns: 'production'
                    }).toLowerCase()
                }),
                this.$t('prod_reiniging', {
                    ns: 'production'
                }).toLowerCase()
            );
        },
        activityList() {
            let flexList = [];
            flexList =
                this.activiteitenLijst.length > 0
                    ? this.activiteitenLijst.map((x) => ({
                          value: parseInt(x.id),
                          text: x.naam
                      }))
                    : [];
            return flexList;
        },
        typeList() {
            let flexList = [];
            flexList =
                this.typeLijst.length > 0
                    ? this.typeLijst.map((x) => ({
                          value: parseInt(x.id),
                          text: x.naam
                      }))
                    : [];
            return flexList;
        },
        itemAdded() {
            return this.maintenanceList.length > 0;
        },
        getmaintenanceList() {
            return this.maintenanceList;
        },
        ostLabel() {
            return this.$t('prod_voorobject', {
                ns: 'production',
                val: this.$t('prod_ost', {
                    ns: 'production'
                }).toLowerCase()
            });
        },
        locationLabel() {
            let result = '';
            switch (this.displayNumber) {
                case 'silo':
                    result = this.$t('prod_silo', {
                        ns: 'production'
                    });
                    break;
                case 'loc':
                    result = this.$t('com_location', {
                        ns: 'common'
                    });
                    break;
                case 'eq':
                    result = this.$t('prod_apparaat', {
                        ns: 'production'
                    });
                    break;
                default: {
                    result = this.$t('prod_onbekend', {
                        ns: 'production'
                    });
                    break;
                }
            }
            return this.$t('prod_voorobject', {
                ns: 'production',
                val: result.toLowerCase()
            });
        },
        showActivities() {
            return this.localType == window.constants.MAINTENANCETYPE_CLEANING;
        }
    },
    methods: {
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        addActivity(hoe) {
            let activity = null;
            let typ = null;
            this.isChanged = true;
            typ = this.$store.getters['maintenance/getOnderhoudsTypeByID'](
                this.localType
            );
            const findID = hoe ? 0 : this.localActivity;
            activity =
                this.$store.getters['maintenance/getOnderhoudsActivityByID'](
                    findID
                );
            const nieuwOnderhoud = new Onderhoud({});
            (nieuwOnderhoud.tijdstip = this.tijdstip),
                (nieuwOnderhoud.locatieID = this.localLocation),
                (nieuwOnderhoud.wbID = this.localOst),
                (nieuwOnderhoud.apparaatID = this.localApparaat),
                (nieuwOnderhoud.ondType = typ),
                (nieuwOnderhoud.ondActiviteit = activity),
                (nieuwOnderhoud.opmerking = this.activiteitOpmerking);

            this.maintenanceList.push(nieuwOnderhoud);
            this.activiteitOpmerking = '';
        },
        checkFormValidity() {
            let tekst = '';
            if (this.objectOnderhoud)
                tekst += this.checkObjectValidity(this.objectOnderhoud);
            const valid = !tekst;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            if (this.isChanged) {
                if (
                    (this.localLocation == undefined &&
                        this.localApparaat == undefined &&
                        this.localOst == undefined) ||
                    this.maintenanceList.length == 0
                ) {
                    this.showAlert(
                        this.$t(com_ongeldigeinput, { ns: 'commmon' })
                    );
                    return;
                }
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return;
                }
                this.saveThisOnderhoud();
                this.$emit('reiniging_refresh');
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-itemMaintenance');
            });
        },
        async saveThisOnderhoud() {
            let MaintenanceModel = null;
            for (let i = 0; i < this.maintenanceList.length; i++) {
                if (i == 0) {
                    MaintenanceModel = this.maintenanceList[i].SetForAdd();
                }
                const act = this.maintenanceList[i].ondActiviteit
                    ? this.maintenanceList[i].ondActiviteit.id
                    : null;
                const mItem = {
                    remark: this.maintenanceList[i].opmerking,
                    type: this.maintenanceList[i].ondType.id,
                    activity: act
                };
                MaintenanceModel.Items.push(mItem);
            }
            if (MaintenanceModel)
                await loadItem(
                    'maintenance/addMaintenance',
                    MaintenanceModel,
                    this.$t
                );
            return true;
        },
        async setOnderhoudsActiviteiten() {
            this.activiteitenLijst = await loadList(
                'maintenance/getOnderhoudsActivitiesLijst',
                'maintenance/loadMaintenanceActivities',
                null,
                true,
                this.$t
            );
        },
        async setOnderhoudsTypes() {
            this.typeLijst = await loadList(
                'maintenance/getOnderhoudsTypeLijst',
                'maintenance/loadMaintenaceTypes',
                null,
                true,
                this.$t
            );
        }
    },
    mounted() {
        this.tijdstip = new Date();
        this.setOnderhoudsActiviteiten();
        this.setOnderhoudsTypes();
        this.localActivity = window.constants.BASE_MAINTENANCEACTIVITY;
        this.localType = window.constants.BASE_MAINTENANCETYPE;
        this.localOst = this.ostID;
    },
    async created() {
        if (this.displayNumber == 'ost') {
            //  haal de wrongelbereiders op
            this.wrongelbereiderList = await loadList(
                'masterdata/getOstLijst',
                'masterdata/loadOsts',
                null,
                true,
                this.$t
            );
        } else {
            if (this.displayNumber == 'eq') {
                this.apparatenList = await loadList(
                    'masterdata/getEquipmentLijst',
                    'masterdata/loadEquipments',
                    null,
                    true,
                    this.$t
                );
            } else {
                const payload = {
                    data: null,
                    filter: this.displayNumber
                };
                await loadItem('masterdata/loadLocations', payload, this.$t);
                this.locationList =
                    this.$store.getters['masterdata/getLocationLijst'];
            }
        }
    }
};
</script>

