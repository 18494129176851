export { Onderhoud, OnderhoudsType, OnderhoudsActivity };
import DateHelper from './DateHelper';

class Onderhoud {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.ma_id ?? data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            tijdstip: {
                type: Date,
                value: data.bookDate ?? data.tijdstip,
                writable: true,
                enumerable: true
            },
            locatie: {
                type: String,
                value: data.object,
                writable: true,
                enumerable: true
            },
            activiteitTekst: {
                type: String,
                value: data.activiteit,
                writable: true,
                enumerable: true
            },
            typeTekst: {
                type: String,
                value: data.soort,
                writable: true,
                enumerable: true
            },
            locatieID: {
                type: Number,
                value: data.stockLocationID,
                writable: true,
                enumerable: true
            },
            wbID: {
                type: Number,
                value: data.ostID,
                writable: true,
                enumerable: true
            },
            apparaatID: {
                type: Number,
                value: data.equipmentID,
                writable: true,
                enumerable: true
            },        

            // group
            opmerking: {
                type: String,
                value: data.remark ?? data.opmerking,
                writable: true,
                enumerable: true
            },
            ondType: {
                type: OnderhoudsType,
                value: data.type
                    ? data.type
                    : 0,
                writable: true,
                enumerable: true
            },
            ondActiviteit: {
                type: OnderhoudsActivity,
                value: data.activity
                    ? data.activity
                    : 0,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = (this.id == undefined || this.id < 0);
    }

    SetForSave() {
        this.sh_descriptivetext = this.naam;
        this.sh_shape = this.vorm;
    }
    SetForAdd() {        
        const MaintenanceModel = {
            BookDate: DateHelper.localDateToUTC(this.tijdstip),
            StockLocationID: this.locatieID,
            OstID: this.wbID,
            EquipmentID: this.apparaatID,
            Items: []
        };
        return MaintenanceModel;
    }
    CheckValidity(func) {
        const warnText = '';
        return warnText;
    }
}


class OnderhoudsType {
    constructor(data, func) {
        this.id = -1;
        this.type = 'OnderhoudsType';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: OnderhoudsType.giveName(data.id, func),
                writable: true,
                enumerable: true
            }
        });
        this.isNew = (this.id == undefined || this.id < 0);
    }
    static giveName(id, func) {
        if (id == undefined) return undefined;
        const lookup = 'ondtype_'+ id.toString();
        return id>=0 ? func(lookup, { ns: 'permissies' }) : undefined;
    }
}

class OnderhoudsActivity {
    constructor(data, func) {
        this.id = -1;
        this.type = 'OnderhoudsActivity';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: OnderhoudsActivity.giveName(data.id, func),
                writable: true,
                enumerable: true
            }
        });
        this.isNew = (this.id == undefined || this.id < 0);
    }
    static giveName(id, func) {
        if (id == undefined) return undefined;
        const lookup = 'ondact_'+id.toString();
        return id>=0 ? func(lookup, { ns: 'permissies' }) : undefined;
    }
}