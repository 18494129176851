export default class Helper {
    static stringformat = (str2Format, ...args) =>
        str2Format.replace(
            /(\{\d+\})/g,
            (a) => args[+a.substr(1, a.length - 2) || 0]
        );

    static concatString(basisString, toevoeging) {
        const s =
            basisString.length > 0 ? basisString + ', ' + '\n' + '\r' : '';
        return s + toevoeging;
    }

    static checkEmptyString(s) {
        return s != undefined && s.toString() === '' ? null : s;
    }

    static calcSerial(serial, amount) {
        // checks?
        const thenum = serial.replace(/^\D+/g, '');
        const thestring = serial.replace(/[0-9]/g, '');
        const nummer = Number(thenum) + amount;
        const len = thenum.length;
        const result = thestring + nummer.toString().padStart(len, '0');
        return result;
    }

    static CelsiusToFahrenheit(value) {
        if (value==undefined) return null;
        return ((value * 9) / 5 + 32);
    }

    static FahrenheitToCelsius(value) {
        if (value==undefined) return null;
        return  ((value - 32) * 5 / 9);
    }

    static Round(value, digits) {
        if (value==undefined) return null;
        if (digits==undefined) return null;
        const x = Math.pow(10, digits);
        return Math.round(x * value) / x;
    }

    static containsChars(serial) {
        const thestring = serial.replace(/[0-9]/g, '');
        return thestring.length > 0 ? true : false;
    }

    static giveCharNumber(serial) {
        const thenum = serial.replace(/^\D+/g, '');
        const thestring = serial.replace(/[0-9]/g, '');
        return { letter: thestring, nummer: thenum };
    }

    static createCompanyAdres(aCompany) {
        if (aCompany == undefined) return 'adres onbekend';
        const naam = aCompany.naam ?? '..';
        const adres = aCompany.adresStraat ?? '..';
        const postcode = aCompany.postcodeStraat ?? '..';
        const plaats = aCompany.plaatsStraat ?? '..';
        const land = aCompany.land ?? '..';
        let s = naam + '\n' + '\r';
        s += adres + '\n' + '\r';
        s += postcode.toUpperCase() + ' ' + plaats.toUpperCase() + '\n' + '\r';
        s += land + '\n' + '\r';
        return s;
    }

    static showBerichten(lijst, func, toast) {
        for (let i = 0; i < lijst.length; i++) {
            const strBericht = lijst[i].getBericht(func);
            const strTitel = lijst[i].getBerichtTitel(func);
            const isError = lijst[i].berichttype
                ? lijst[i].berichttype == 'error'
                : false;
            toast.toast(strBericht, {
                title: strTitel,
                variant: isError ? 'danger' : 'warning',
                autoHideDelay: 5000,
                toaster: 'b-toaster-bottom-right',
                appendToast: true
            });
        }
    }

    static setChanged(valueNew, valueOld, boolvalue) {
        if (boolvalue) return boolvalue;
        if (valueNew == undefined) {
            return valueOld == undefined ? false : true;
        } else {
            return valueOld == undefined ? true : valueNew != valueOld;
        }
    }

    static getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
    }

    static compareListsById(list1, list2) {
        let isAnders = list1.length !== list2.length;
        if (!isAnders) {
            for (let index = 0; index < list1.length; index++) {
                const id = list1[index].id;
                const test = list2.find((x) => x.id === id);
                isAnders = test == undefined;
                if (isAnders) break;
            }
        }
        return isAnders;
    }

    static overlapList1(list1, list2, allowdoubles) {
        if (list1 == undefined || list2 == undefined) return [];
        const result = [];
        for (let i = list1.length - 1; i >= 0; i--) {
            const id = list1[i].id;
            const test = list2.find((x) => x.id === id);
            if (test) {
                if (!allowdoubles) {
                    const test2 = result.find((x) => x.id === id);
                    if (test2 == undefined) result.push(test);
                } else {
                    result.push(test);
                }
            }
        }
        return result;
    }

    static filterByValue(array, string) {
        return array.filter((o) =>
            Object.keys(o).some((k) =>
                String(o[k]).toLowerCase().includes(string.toLowerCase())
            )
        );
    }

    static reduceListWithList(list1, list2) {
        if (list1 == undefined || list2 == undefined) return { list1, list2 };
        for (let i = list1.length - 1; i >= 0; i--) {
            const id = list1[i].id;
            const test = list2.find((x) => x.id === id);
            if (test) list1.splice(i, 1);
        }
        return { list1, list2 };
    }

    static moveItem(lijst, from, to) {
        // remove `from` item and store it
        const f = lijst.splice(from, 1)[0];
        // insert stored item into position `to`
        lijst.splice(to, 0, f);
        return lijst;
    }

    static fromListByListID(list1, list2) {
        const resultList = [];
        if (list1 == undefined || list2 == undefined)
            return { list1, resultList };
        for (let i = list1.length - 1; i >= 0; i--) {
            const id = list1[i].id;
            const test = list2.indexOf(id) > -1;
            if (test) resultList.push(list1[i]);
        }
        return { list1, resultList };
    }

    static dynamicSort(property) {
        let sortOrder = 1;
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            const result =
                a[property] < b[property]
                    ? -1
                    : a[property] > b[property]
                    ? 1
                    : 0;
            return result * sortOrder;
        };
    }

    static removeProperty(list, property) {
        for (const el of list) {
            delete el[property];
        }
        return list;
    }

    static replaceValueInObject(list, zoek, vervang) {
        for (let i = 0; i < list.length; i++) {
            const obj = list[i];
            Object.keys(obj).forEach(function (key) {
                if (obj[key] === zoek) {
                    obj[key] = vervang;
                }
            });
        }
        return list;
    }

    static giveHeaderFields(list, func, notrans = false) {
        const fieldsInit = [];
        if (list.length < 1) return fieldsInit;

        const keys = Object.keys(list[0]);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            let lab = func('rep_' + k, { ns: 'report' });
            if (notrans) {
                if (lab.indexOf('rep_') > -1) {
                    lab = k;
                }
            }
            const o = { key: k, label: lab, sortable: false, visible: true };
            fieldsInit.push(o);
        }
        return fieldsInit.filter((field) => field.visible);
    }

    static searchReplaceInString(line, zoek, rep, func) {
        const srep = func(rep, { ns: 'report' });
        return line.replace(zoek, srep);
    }

    // static dynamicSortMultiple() {
    //     /*
    //      * save the arguments object as it will be overwritten
    //      * note that arguments object is an array-like object
    //      * consisting of the names of the properties to sort by
    //      */
    //     var props = arguments;
    //     return function (obj1, obj2) {
    //         var i = 0, result = 0, numberOfProperties = props.length;
    //         /* try getting a different result from 0 (equal)
    //          * as long as we have extra properties to compare
    //          */
    //         while(result === 0 && i < numberOfProperties) {
    //             result = dynamicSort(props[i])(obj1, obj2);
    //             i++;
    //         }
    //         return result;
    //     }
    // }

    static sumList(list1, start = 0) {
        const initialValue = start;
        const sumWithInitial = list1.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            initialValue
        );
        return sumWithInitial;
    }

    static inRange(x, min, max) {
        return (x - min) * (x - max) <= 0;
    }

    static distinctList(list1, key) {
        if (!key) {
            return list1;
        }
        return [
            ...new Map(
                list1.filter(Boolean).map((item) => [item[key], item])
            ).values()
        ];
    }

    static checkDoubles = (array = [], keys = []) => {
        if (!keys.length || !array.length) return true;

        const mapped = array.map((entry) => {
            let ultKey = '';
            for (let i = 0; i < keys.length; i++) {
                ultKey = ultKey.concat('=====', entry[keys[i]]);
            }
            return ultKey;
        });
        const duplicates = mapped.filter(
            (item, index) => mapped.indexOf(item) !== index
        );
        return duplicates.length > 0 ? true : false;
    };

    static giveNextFreeId(list1) {
        let result = -1; // standaard
        for (let index = 0; index < list1.length; index++) {
            const id = list1[index].id;
            if (id < 0) {
                if (id <= result) result = id - 1;
            }
        }
        return result;
    }
}

