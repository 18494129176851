<template>
    <b-container fluid style="margin-top: 60px">
        <h1>{{ title }}</h1>
        <div class="form-inline">
            <external-link-selector
                v-model="anID"
                @inputtype="setType"
                @inputsubtype="setSubType"
                @input="reloadData"
            />
            &nbsp;
        </div>
        <b-row>
            <b-col sm="12">
                <b-card>
                    <b-tabs card>
                        <b-tab
                            :title="$t('com_product', { ns: 'common' })"
                            v-bind:key="1"
                            active
                        >
                            <div v-if="showFilter">
                                <b-row no-gutters>
                                    <b-col lg="6" class="my-1">
                                        <b-form-group
                                            :label="
                                                $t('com_filter', {
                                                    ns: 'common'
                                                })
                                            "
                                            label-for="filter-input"
                                            label-cols-sm="3"
                                            label-align-sm="right"
                                            label-size="sm"
                                            class="mb-0"
                                        >
                                            <b-input-group size="sm">
                                                <b-form-input
                                                    id="filter-input"
                                                    v-model="filter"
                                                    type="search"
                                                    :placeholder="
                                                        $t('com_typetosearch', {
                                                            ns: 'common'
                                                        })
                                                    "
                                                ></b-form-input>

                                                <b-input-group-append>
                                                    <b-button
                                                        :disabled="!filter"
                                                        @click="filter = ''"
                                                        >{{
                                                            $t('com_wis', {
                                                                ns: 'common'
                                                            })
                                                        }}</b-button
                                                    >
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="6" class="my-1">
                                        <b-form-group
                                            :label="
                                                $t('com_perpagina', {
                                                    ns: 'common'
                                                })
                                            "
                                            label-for="per-page-select"
                                            label-cols-sm="6"
                                            label-cols-md="4"
                                            label-cols-lg="3"
                                            label-align-sm="right"
                                            label-size="sm"
                                            class="mb-0"
                                        >
                                            <b-form-select
                                                id="per-page-select"
                                                v-model="perPage"
                                                :options="pageOptions"
                                                size="sm"
                                            ></b-form-select>
                                            <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                                align="fill"
                                                size="sm"
                                                class="my-0"
                                            ></b-pagination>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>
                            <b-table
                                :items="displayList"
                                :fields="visibleFields"
                                :current-page="currentPage"
                                :per-page="perPage"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                stacked="md"
                                show-empty
                                :empty-text="
                                    $t('com_geen_data', { ns: 'common' })
                                "
                                small
                                striped
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(actions)="row">
                                    <b-button
                                        v-if="
                                            checkRight(
                                                'ChangeExternalCommnication'
                                            )
                                        "
                                        size="sm"
                                        @click="unlinkItem(row.item)"
                                        class="bgc_aux_alarm"
                                        ><font-awesome-icon
                                            icon="trash-alt"
                                            size="1x"
                                        />
                                    </b-button>
                                    <b-dropdown
                                        v-if="
                                            checkRight(
                                                'ChangeExternalCommnication'
                                            )
                                        "
                                        id="dropdown-form"
                                        text="..."
                                        ref="dropdown"
                                        size="sm"
                                        @show="setStartValues(row.item)"
                                    >
                                        <div class="fluid-container">
                                            <b-dropdown-form>
                                                <div>
                                                    <b-form-group
                                                        :label="
                                                            $t('com_koppel', {
                                                                ns: 'common'
                                                            })
                                                        "
                                                        label-for="dropdown-form-correct"
                                                    >
                                                        <b-form-select
                                                            v-model="externalID"
                                                            :options="
                                                                tobeLinked
                                                            "
                                                        >
                                                            <template #first>
                                                                <b-form-select-option
                                                                    value=""
                                                                    >--
                                                                    {{
                                                                        $t(
                                                                            'com_selecteer',
                                                                            {
                                                                                ns: 'common'
                                                                            }
                                                                        )
                                                                    }}
                                                                    --</b-form-select-option
                                                                >
                                                            </template>
                                                        </b-form-select>
                                                        <b-button
                                                            class="float-right"
                                                            variant="primary"
                                                            @click="
                                                                saveLink(
                                                                    row.item
                                                                )
                                                            "
                                                            >Ok</b-button
                                                        >
                                                    </b-form-group>
                                                </div>
                                                <b-dropdown-divider></b-dropdown-divider>
                                            </b-dropdown-form>
                                        </div>
                                    </b-dropdown>
                                </template>
                            </b-table>
                        </b-tab>
                        <b-tab @click="loadImportedData()" lazy>
                            <template #title>
                                <b-spinner
                                    v-if="showSpinner"
                                    type="border"
                                    small
                                ></b-spinner>
                                {{ lijstTitel }}
                            </template>
                            <div v-if="showFilter1">
                                <b-row no-gutters>
                                    <b-col lg="6" class="my-1">
                                        <b-form-group
                                            :label="
                                                $t('com_filter', {
                                                    ns: 'common'
                                                })
                                            "
                                            label-for="filter-input"
                                            label-cols-sm="3"
                                            label-align-sm="right"
                                            label-size="sm"
                                            class="mb-0"
                                        >
                                            <b-input-group size="sm">
                                                <b-form-input
                                                    id="filter-input"
                                                    v-model="filter1"
                                                    type="search"
                                                    :placeholder="
                                                        $t('com_typetosearch', {
                                                            ns: 'common'
                                                        })
                                                    "
                                                ></b-form-input>

                                                <b-input-group-append>
                                                    <b-button
                                                        :disabled="!filter1"
                                                        @click="filter1 = ''"
                                                        >{{
                                                            $t('com_wis', {
                                                                ns: 'common'
                                                            })
                                                        }}</b-button
                                                    >
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="6" class="my-1">
                                        <b-form-group
                                            :label="
                                                $t('com_perpagina', {
                                                    ns: 'common'
                                                })
                                            "
                                            label-for="per-page-select"
                                            label-cols-sm="6"
                                            label-cols-md="4"
                                            label-cols-lg="3"
                                            label-align-sm="right"
                                            label-size="sm"
                                            class="mb-0"
                                        >
                                            <b-form-select
                                                id="per-page-select"
                                                v-model="perPage1"
                                                :options="pageOptions"
                                                size="sm"
                                            ></b-form-select>
                                            <b-pagination
                                                v-model="currentPage1"
                                                :total-rows="totalRows1"
                                                :per-page="perPage1"
                                                align="fill"
                                                size="sm"
                                                class="my-0"
                                            ></b-pagination>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>
                            <b-card-text>
                                <b-table
                                    striped
                                    responsive
                                    :fields="fieldsToon"
                                    :items="inLijst"
                                    primary-key
                                    :current-page="currentPage1"
                                    :per-page="perPage1"
                                    :filter="filter1"
                                    :filter-included-fields="filterOn1"
                                    show-empty
                                    :empty-text="
                                        $t('com_geen_data', { ns: 'common' })
                                    "
                                    @filtered="onFiltered1"
                                >
                                </b-table>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import _ from 'lodash';
import ExternalLinkSelector from './ExternalLinkSelector';
import { Permission } from '../../models/Permissions';
import PRODCAT_ENUM from '@/models/ProductCategorie';
import Helper from '@/models/Helper';
import DateHelper from '../../models/DateHelper';
import { loadItem, loadList } from '@/models/DataHelper';

export default {
    name: 'ExternalLinksBase',
    data: function () {
        return {
            totalRows: 1,
            totalRows1: 1,
            currentPage: 1,
            currentPage1: 1,
            perPage: 20,
            perPage1: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filter1: null,
            filterOn: [],
            filterOn1: [],
            anID: -1,
            externalID: '',
            tableType: -1,
            subType: -1,
            refreshedItem: null,
            showSpinner: false,
            localList: [],
            localLinkList: [],
            inLijst: [],
            dsLijst: [],
            displayFilterCount: 10,
            displayFilter1Count: 10,
            teller: null
        };
    },
    props: {},
    components: {
        ExternalLinkSelector
    },
    computed: {
        title() {
            return this.$t('com_beschikbaar', {
                ns: 'common',
                val: this.$t('com_extkoppelingen', {
                    ns: 'common'
                }).toLowerCase()
            });
        },
        lijstTitel() {
            let obj = '';
            const source = this.dsLijst.find((x) => x.key == this.tableType);
            const sourcenaam = source ? source.naam : '??';
            switch (this.tableType) {
                case window.constants.ELT_PRODUCT: {
                    obj = this.$t('com_products', {
                        ns: 'common'
                    }).toLowerCase();
                    break;
                }
                default: {
                    obj = this.$t('com_products', {
                        ns: 'common'
                    }).toLowerCase();
                    break;
                }
            }
            return Helper.stringformat(
                this.$t('com_importedfrom', { ns: 'common' }),
                obj,
                sourcenaam
            );
        },
        showFilter() {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        showFilter1() {
            return this.inLijst
                ? this.inLijst.length > this.displayFilter1Count
                : false;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'internID',
                    label: this.$t('com_internalid', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'naam',
                    label: this.$t('com_naam', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'externID',
                    label: this.$t('com_externalid', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'externNaam',
                    label: this.$t('com_externalname', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        fieldsToon() {
            const fieldsInit = [
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'extid',
                    label: this.$t('com_externalid', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'naam',
                    label: this.$t('com_naam', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'externID',
                    label: this.$t('com_externalid', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'type',
                    label: this.$t('com_keynaam', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'unit',
                    label: this.$t('com_unit', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'toelichting',
                    label: this.$t('com_opmerking', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'error',
                    label: this.$t('com_melding', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            const a = this.teller;
            let result = [];
            if (this.tableType == window.constants.ELT_PRODUCT) {
                if (this.subType == window.constants.PRODUCTCAT) {
                    result = this.localList.filter(
                        (x) => x.prodcat == this.anID
                    );
                } else {
                    result = this.localList.filter(
                        (x) => x.ingredientcat == this.anID
                    );
                }
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = result ? result.length : 0;
            return result;
        },
        tobeLinked() {
            let result = [];
            if (this.tableType == window.constants.ELT_PRODUCT) {
                result = this.localLinkList.slice(0).map((x) => ({
                    value: x.value,
                    text: x.text,
                    disabled: this.alGebruikt(x.value)
                }));
            }
            return result;
        }
    },
    methods: {
        alGebruikt(code) {
            return (
                this.localList.filter((x) => {
                    return x.externID == code;
                }).length > 0
            );
        },
        setType(value) {
            this.tableType = value;
        },
        setSubType(value) {
            this.subType = value;
        },
        async getDetailItem(item) {
            let result = null;
            if (this.tableType == window.constants.ELT_PRODUCT) {
                result = await loadItem(
                    'product/loadProduct',
                    { productID: item.id },
                    this.$t
                );
            }
            return result;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows1 = filteredItems.length;
            this.currentPage1 = 1;
        },
        onFiltered1(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async reloadData(value) {
            const id = value ?? this.anID;
            if (this.tableType == window.constants.ELT_PRODUCT) {
                this.localList = await loadList(
                    'product/getAllProductsLijst',
                    'product/loadAllProducts',
                    null,
                    true,
                    this.$t
                );
                this.teller += 1;
            }
            this.loadLinkList();
        },
        async loadLinkList() {
            if (this.tableType == window.constants.ELT_PRODUCT) {
                const linked = this.localList
                    .filter((x) => x.externID != undefined)
                    .map((x) => x.externID);
                const temp = await loadList(
                    'extern/getLinkItemsLijst',
                    'extern/loadExternalProducts',
                    null,
                    true,
                    this.$t
                );
                const free = temp.filter((x) => {
                    return linked.indexOf(x.extid) < 0;
                });
                this.localLinkList = free.map((x) => ({
                    value: x.extid,
                    text: x.naam + ' (' + x.extid + ')',
                    type: x.type // om te filteren
                }));
            }
        },
        async setStartValues(item) {
            this.refreshedItem = await this.getDetailItem(item);
        },
        async saveLink(item) {
            if (this.tableType == window.constants.ELT_PRODUCT) {
                let overwrite = true;
                if (this.refreshedItem.externID) {
                    overwrite = await this.$root.showConfirmationBox(
                        this.$t('com_overwritelink', {
                            ns: 'common',
                            val: this.$t('com_product', { ns: 'common' })
                        }) + item.naam
                    );
                }
                if (overwrite) {
                    this.refreshedItem.externID = this.externalID;
                    const model = this.refreshedItem.SetForExternalLink();
                    await loadItem('product/linkProduct', model, this.$t);
                    this.$refs.dropdown.hide(); // werkt niet
                }
            }
            this.reloadData();
        },
        async unlinkItem(item) {
            this.refreshedItem = await this.getDetailItem(item);
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_unlink', {
                    ns: 'common',
                    val: this.$t('com_product', { ns: 'common' })
                }) + item.naam
            );
            if (doClose) {
                this.refreshedItem.externID = undefined;
                const model = this.refreshedItem.SetForExternalLink();
                await loadItem('product/linkProduct', model, this.$t);
            }
            this.reloadData();
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        async loadImportedData() {
            this.showSpinner = true;
            this.inLijst = await loadList(
                'extern/getLinkItemsLijst',
                'extern/loadExternalProducts',
                null,
                false,
                this.$t
            );
            this.totalRows1 = this.inLijst ? this.inLijst.length : 0;
            this.showSpinner = false;
        },
        async loadDatasources() {
            const arr = await loadItem(
                'extern/loadExternalDataSources',
                null,
                this.$t
            );
            this.dsLijst = arr;
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.displayList ? this.displayList.length : 0;
        this.totalRows1 = this.inLijst ? this.inLijst.length : 0;
    },
    async created() {
        this.setType(window.constants.ELT_PRODUCT);
        this.setSubType(window.constants.PRODUCTCAT);
        await this.loadDatasources();
    }
};
</script>

<style scoped></style>
