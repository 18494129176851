<template>
    <b-modal
        id="modal-confirmdetail"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-card @submit.stop.prevent="handleSubmit">
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
            <b-tabs card>
                <b-tab
                    :title="
                        $t('prod_treatment', {
                            ns: 'production'
                        })
                    "
                    v-bind:key="1"
                    active
                >
                    <b-card-text>
                        <div>
                            <b-row>
                                <b-col sm="6">
                                    <b-input-group
                                        :prepend="
                                            $t('prod_action', {
                                                ns: 'production'
                                            })
                                        "
                                    >
                                        <b-form-input
                                            v-model="behandeling"
                                            locale="nl"
                                            readonly
                                        ></b-form-input>
                                    </b-input-group>
                                    <b-input-group
                                        :prepend="
                                            $t('prod_uitgevoerd', {
                                                ns: 'production'
                                            })
                                        "
                                    >
                                        <b-form-timepicker
                                            v-model="uitvoerTijdstip"
                                            locale="nl"
                                            @input="set_changed"
                                            :disabled="!editAllowed"
                                        ></b-form-timepicker>
                                    </b-input-group>
                                    <div v-if="needsCoatingProduct">
                                        <div v-if="baseTAC.coatingproduct">
                                            <b-input-group
                                                :prepend="
                                                    $t('prod_coating', {
                                                        ns: 'production'
                                                    })
                                                "
                                            >
                                                <b-form-select
                                                    key="rerenderkey"
                                                    v-model="partijID"
                                                    @change="set_inputPartij"
                                                    :disabled="!editAllowed"
                                                    v-bind:class="{
                                                        tc_aux_highlighted:
                                                            warnForStockAmount
                                                    }"
                                                >
                                                    <template #first>
                                                        <b-form-select-option
                                                            value=""
                                                            >--
                                                            {{
                                                                $t(
                                                                    'com_selecteer',
                                                                    {
                                                                        ns: 'common'
                                                                    }
                                                                )
                                                            }}
                                                            --</b-form-select-option
                                                        >
                                                    </template>
                                                    <option
                                                        v-for="option in itemStockList"
                                                        :value="option.value"
                                                        :key="option.value"
                                                        v-bind:class="{
                                                            tc_aux_highlighted:
                                                                option.warn
                                                        }"
                                                    >
                                                        {{ option.text }}
                                                    </option>
                                                </b-form-select>
                                            </b-input-group>
                                        </div>
                                        <div v-else>
                                            <b>{{ strNoCoating }}</b>
                                        </div>
                                    </div>
                                    <b-form-textarea
                                        id="bakopmerking"
                                        v-model="refreshedTreatment.opmerking"
                                        :placeholder="
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        "
                                        rows="3"
                                        max-rows="6"
                                        @change="set_changed"
                                    ></b-form-textarea>
                                </b-col>
                                <b-col sm="6">
                                    <show-row-col
                                        :showLabel="
                                            $t('com_kaassoort', {
                                                ns: 'common'
                                            })
                                        "
                                        :showObject="baseTAC.product"
                                    />
                                    <show-row-col
                                        :showLabel="
                                            $t('com_number', {
                                                ns: 'common'
                                            })
                                        "
                                        :showObject="baseTAC.startaantal"
                                    />
                                    <show-row-col
                                        :showLabel="
                                            $t('com_location', {
                                                ns: 'common'
                                            })
                                        "
                                        :showObject="baseTAC.locatie"
                                    />
                                    <show-row-col
                                        :showLabel="
                                            $t('prod_gepland', {
                                                ns: 'production'
                                            })
                                        "
                                        :showObject="planned"
                                    />
                                    <hr />
                                    <b-row class="mx-0 px-0 my-0 py-0">
                                        <b-table
                                            :items="marksList"
                                            :fields="visibleMarksFields"
                                            stacked="md"
                                            show-empty
                                            :empty-text="
                                                $t('com_geen_data', {
                                                    ns: 'common'
                                                })
                                            "
                                            small
                                            striped
                                            responsive
                                        >
                                            <template #cell(startmerk)="data">
                                                {{ data.item.startcharacters }}
                                                &nbsp;
                                                {{ data.item.startnumber }}
                                            </template>
                                            <template #cell(eindmerk)="data">
                                                {{ data.item.endcharacters }}
                                                &nbsp;
                                                {{ data.item.endnumber }}
                                            </template>
                                        </b-table>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card-text>
                </b-tab>

                <b-tab @click="loadActData()" lazy>
                    <template #title>
                        <b-spinner
                            v-if="showSpinner"
                            type="border"
                            small
                        ></b-spinner>
                        {{$t('com_activitylog', { ns: 'common' })}}
                    </template>
                    <b-card-text>
                        <b-table
                            striped
                            responsive
                            :fields="fieldsActiviteiten"
                            :items="activiteitenLijst"
                            primary-key
                        >
                        </b-table>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-modal>
</template>

<script lang="ts">
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import { loadList, loadItem } from '../../models/DataHelper';
import { TreatmentActivity } from '@/models/CheeseTreatment';
import { VoorraadItem } from '@/models/Voorraad';
import { ShowRowCol } from '../components';

export default defineComponent({
    name: 'ConfirmTreatmentModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            showSpinner: false,
            fieldsActiviteiten: [
                {
                    key: 'gemaakt',
                    label: this.$t('prod_gemaakt', { ns: 'production' })
                },
                {
                    key: 'actie',
                    label: this.$t('prod_activity', { ns: 'production' })
                },
                {
                    key: 'persoon',
                    label: this.$t('prod_loggedin', { ns: 'production' })
                }
            ],
            activityList: [],
            flexList: [] as Array<VoorraadItem>,
            refreshedTreatment: {} as TreatmentActivity,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            refreshedMarks: {} as any | null,
            currentBak: null,
            currentProdRecept: null,
            bakID: -1,
            aangeraakt_teller: 1,
            localChanged: true,
            showBakDetails: false,
            editAllowed: true,
            warnAmount: false,
            forceRefresh: false,
            uitvoerTijdstip: DateHelper.convertedToTime(new Date()),
            partijID: -1,
            prevPartijID: -1,
            maxDelay: 12,
            rerenderkey: 1,
            strStockEmpty: this.$t('com_stockempty', { ns: 'common' }),
            strOpschuiven: this.$t('prod_warntreatmentnotintime', {
                ns: 'production'
            }),
            strNoCoating: this.$t('prod_warnnocoating', {
                ns: 'production'
            }),
            strSaveWithoutProduct: this.$t('prod_warnnocoatingproduct', {
                ns: 'production'
            })
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        treatmentActivityId: {
            type: Number,
            required: true
        },
        treatmentActivity: {
            type: TreatmentActivity,
            required: true
        },
        behandeling: {
            type: String,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ShowRowCol
    },
    watch: {},
    computed: {
        formtitle() {
            return (
                this.$t('com_detail', { ns: 'common' }) +
                ' ' +
                this.$t('prod_treatment', { ns: 'production' }).toLowerCase()
            );
        },
        baseTAC() {
            return this.treatmentActivity;
        },
        marksList() {
            return this.refreshedMarks != undefined
                ? this.refreshedMarks.data != undefined
                    ? this.refreshedMarks.data.Items
                    : []
                : [];
        },
        activiteitenLijst() {
            return this.activityList;
        },
        warnForStockAmount() {
            return this.warnAmount;
        },
        planned() {
            return DateHelper.convertedToDate(this.baseTAC.starttijdplan, true);
        },
        needsCoatingProduct() {
            return (
                this.refreshedTreatment.behandelstapID ==
                    window.constants.COATING_STEPID ||
                this.refreshedTreatment.behandelstapID >
                    window.constants.START_SEQ
            );
        },
        itemStockList() {
            const tempList = this.flexList.map((x) => {
                const tekst = x.datum
                    ? Helper.stringformat(
                          '{0} (' +
                              this.$t('com_tht', { ns: 'common' }) +
                              ': {1})',
                          x.batchID,
                          DateHelper.convertedToDateShort(x.datum)
                      )
                    : x.batchID;
                return {
                    value: x.id,
                    text: tekst,
                    warn: x.waarschuwen
                };
            });

            return tempList;
        },
        existing() {
            return this.treatmentActivityId > 0;
        },
        visibleMarksFields() {
            const fieldsInit = [
                {
                    key: 'number',
                    label: this.$t('prod_bak', {
                        ns: 'production'
                    }),
                    visible: true
                },
                {
                    key: 'marktype',
                    label: this.$t('com_marktype', {
                        ns: 'common'
                    }),
                    visible: true
                },
                {
                    key: 'startmerk',
                    label: this.$t('prod_startmerk', {
                        ns: 'production'
                    }),
                    visible: true
                },
                {
                    key: 'eindmerk',
                    label: this.$t('prod_eindmerk', {
                        ns: 'production'
                    }),
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide');
        },
        showDate(aDate) {
            return DateHelper.convertedToDateShort(aDate);
        },
        set_input() {
            this.refreshedTreatment.coatingproductID =
                this.partijID > -1 ? this.partijID : null;
            this.$emit('change', this.refreshedTreatment);
        },
        async checkReplan() {
            let result = false;
            // voorlopig alleen vandaag afmelden
            const tijd = DateHelper.convertedFromTimepicker(
                new Date(),
                this.uitvoerTijdstip
            );
            this.refreshedTreatment.starttijd = tijd;
            const uren = DateHelper.dateDiffinHours(
                tijd,
                this.treatmentActivity.starttijdplan
            );
            if (Math.abs(uren) > this.maxDelay) {
                const kant =
                    uren < 0
                        ? this.$t('prod_vroeg', {
                              ns: 'production'
                          })
                        : this.$t('prod_laat', {
                              ns: 'production'
                          });
                const message = Helper.stringformat(
                    this.strOpschuiven,
                    Math.abs(Math.round(uren)),
                    kant
                );
                const herplannen = await (
                    this.$root as VueExtension
                ).showConfirmationBox(message);
                if (herplannen) {
                    result = herplannen;
                    this.refreshedTreatment.herplannen = herplannen;
                }
            }
            return result;
        },
        set_changed() {
            this.localChanged = true;
        },
        async loadStockItemList(productID) {
            const selectionData = { productid: productID };
            const locList = await loadItem(
                'stock/loadStockByProduct',
                selectionData,
                this.$t
            );
            this.flexList = locList;
            if (locList.length > 0) {
                this.partijID = locList[0].id;
                if (this.prevPartijID == -1) this.prevPartijID == locList[0].id;
                await this.set_inputPartij();
                this.rerenderkey += 1;
            }
            if (this.itemStockList.length < 1 && !this.existing)
                console.log(
                    this.$t('com_geen_data', {
                        ns: 'common'
                    })
                );
        },
        async set_inputPartij() {
            const stockItem = this.flexList.find((x) => x.id == this.partijID);
            this.warnAmount = stockItem ? stockItem.aantal < 0 : false;
            if (
                this.partijID &&
                this.prevPartijID > -1 &&
                this.partijID != this.prevPartijID
            ) {
                const o = this.flexList.find((x) => x.id == this.prevPartijID);
                const message = Helper.stringformat(
                    this.strStockEmpty,
                    o?.batchID
                );
                const doClose = await (
                    this.$root as VueExtension
                ).showConfirmationBox(message);
                if (doClose) {
                    // zet de voorraad op leeg
                    loadItem(
                        'stock/loadStockItem',
                        {
                            stockitemid: this.prevPartijID
                        },
                        this.$t
                    ).then((x) => {
                        const selectionData = {
                            ID: x.id,
                            LastUpdated: x.gewijzigd,
                            IsEmpty: true,
                            NeverUseAgain: false
                        };
                        loadItem(
                            'stock/closeStockItem',
                            selectionData,
                            this.$t
                        );
                    });
                    // .then(() => {
                    //     this.loadStockItemList(
                    //         this.treatmentActivity.coatingproductID
                    //     );
                    // });
                }
                this.set_changed();
            }
            this.prevPartijID = this.partijID;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            if (this.needsCoatingProduct) {
                if (this.partijID < 0) {
                    if (!this.showDismissibleAlert) {
                        this.showAlert(this.strSaveWithoutProduct);
                        return;
                    }
                }
            }
            if (this.localChanged) {
                const herplannen = await this.checkReplan();
                this.refreshedTreatment.herplannen = herplannen;
                this.set_input();
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-confirmdetail');
            });
        },
        giveBakTitle(element) {
            return 'Bak ' + element.number;
        },
        async getData() {
            if (this.treatmentActivityId > -1) {
                this.refreshedMarks = null;
                this.refreshedTreatment = await loadItem(
                    'treatment/loadTreatment',
                    {
                        treatmentActivityID: this.treatmentActivityId
                    },
                    this.$t
                );
                this.refreshedMarks = await loadItem(
                    'stock/loadStockItemMarksInfo',
                    {
                        batchgroup: this.treatmentActivity.batchnummer
                    },
                    this.$t
                );
            }
        },
        async loadActData() {
            // todo zinvol?
            this.showSpinner = true;
            this.activityList = await loadItem(
                'treatment/loadTreatmentActiviteitenLijst',
                {
                    measurementID: this.treatmentActivityId
                },
                this.$t
            );
            this.showSpinner = false;
        }
    },
    mounted() {
        this.getData();
    },
    async created() {
        const lijst = await loadList(
            'masterdata/getConstantsLijst',
            'masterdata/loadApplicationConstants',
            null,
            this.forceRefresh,
            this.$t
        );
        const con = lijst
            ? lijst.find(
                  (x) =>
                      x.text == window.constants.APPCONST_ALLOWEDDELAYTREATMENT
              )
            : null;
        this.maxDelay = con ? con.value : 12;
        if (this.treatmentActivity.coatingproductID) {
            this.loadStockItemList(this.treatmentActivity.coatingproductID);
        }
        if (this.treatmentActivity.starttijd) {
            this.uitvoerTijdstip = DateHelper.convertedToTime(
                DateHelper.convertedToDateTimeString(
                    this.treatmentActivity.starttijd
                )
            );
            this.localChanged = false;
        }
    }
});
</script>

<style scoped></style>

