import { render, staticRenderFns } from "./ModalSample.vue?vue&type=template&id=0fe6dc87&scoped=true"
import script from "./ModalSample.vue?vue&type=script&lang=js"
export * from "./ModalSample.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fe6dc87",
  null
  
)

export default component.exports