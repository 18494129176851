var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"no-body":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-alert',{attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{attrs:{"title":_vm.$t('com_basis', { ns: 'common' }),"active":""}},[_c('b-card-text',[_c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[(_vm.editAllowed)?_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t('prod_validfrom', { ns: 'production' }))+" ")])]):_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t('com_recipe', { ns: 'common' }))+"#: ")]),_c('b',[_vm._v("   "+_vm._s(_vm.giveRecipeID))])])]),_c('b-col',{attrs:{"sm":"9"}},[(_vm.editAllowed)?_c('div',[_c('b-form-datepicker',{attrs:{"id":"dateVanaf","min":_vm.minDate,"max":_vm.maxDate},model:{value:(_vm.localItem.vanaf),callback:function ($$v) {_vm.$set(_vm.localItem, "vanaf", $$v)},expression:"localItem.vanaf"}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.giveRecipeTimestring)+" ")])])],1),(_vm.editAllowed)?_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-itemnaam"}},[_vm._v(_vm._s(_vm.$t('com_kaassoort', { ns: 'common' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('lookup-combo',{attrs:{"id":"input-itemnaam","placeholder":_vm.$t('com_kaassoort', {
                                                    ns: 'common'
                                                }),"disabled":_vm.kaasenable,"combotype":_vm.locallctype,"zelf":_vm.productCheese},on:{"inputnaam":_vm.setChangedKaassoort},model:{value:(_vm.localItem.productID),callback:function ($$v) {_vm.$set(_vm.localItem, "productID", $$v)},expression:"localItem.productID"}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-yield"}},[_vm._v(_vm._s(_vm.$t('com_opbrengst', { ns: 'common' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('com_opbrengstmaart', {
                                                    ns: 'common'
                                                }),"id":"input-yield","placeholder":_vm.$t('com_opbrengstplace', {
                                                    ns: 'common'
                                                }),"type":"number","step":"0.1","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localItem.melkgebruik
                                            ),callback:function ($$v) {_vm.$set(_vm.localItem, "melkgebruik", _vm._n($$v))},expression:"\n                                                localItem.melkgebruik\n                                            "}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-itemremark"}},[_vm._v(_vm._s(_vm.$t('com_opmerking', { ns: 'common' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-textarea',{attrs:{"id":"input-itemremark","placeholder":_vm.$t('com_opmerking', {
                                                    ns: 'common'
                                                }),"disabled":!_vm.editAllowed,"rows":"3"},on:{"change":_vm.setChanged},model:{value:(_vm.localItem.opmerking),callback:function ($$v) {_vm.$set(_vm.localItem, "opmerking", $$v)},expression:"localItem.opmerking"}})],1),_c('b-col',{attrs:{"sm":"3"}}),_c('b-col',{attrs:{"sm":"9"}},[(_vm.configUseWei2a)?_c('b-form-checkbox',{attrs:{"value":"true","unchecked-value":"false"},on:{"change":_vm.rerender},model:{value:(_vm.useWei2a),callback:function ($$v) {_vm.useWei2a=$$v},expression:"useWei2a"}},[_vm._v(" "+_vm._s(_vm.strUseWei2a)+" ")]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-strem"}},[_vm._v(_vm._s(_vm.$t('prod_xtemp', { ns: 'production', val: _vm.$t('prod_strem', { ns: 'production' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('temperature-input',{attrs:{"id":"input-strem","editAllowed":_vm.editAllowed,"showFahrenheit":_vm.configUseFahrenheit,"minvalue":_vm.minStremTemp,"maxvalue":_vm.maxStremTemp,"placeholderC":_vm.$t('prod_xtemperatuur', {
                                                    ns: 'production',
                                                    val: _vm.$t('prod_strem', {
                                                        ns: 'production'
                                                    })
                                                })},on:{"change":_vm.setChanged},model:{value:(_vm.localItem.stremTemp),callback:function ($$v) {_vm.$set(_vm.localItem, "stremTemp", _vm._n($$v))},expression:"localItem.stremTemp"}})],1)],1),(_vm.useWei2a)?_c('b-row',{key:"rerenderkey"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-tussen"}},[_vm._v(_vm._s(_vm.$t('prod_xtemp', { ns: 'production', val: _vm.$t('prod_tussentemp', { ns: 'production' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('temperature-input',{attrs:{"id":"input-tussen","editAllowed":_vm.editAllowed,"showFahrenheit":_vm.configUseFahrenheit,"minvalue":_vm.minNawarmTemp,"maxvalue":_vm.maxNawarmTemp,"placeholderC":_vm.$t('prod_xtemperatuur', {
                                                    ns: 'production',
                                                    val: _vm.$t('prod_tussentemp', {
                                                        ns: 'production'
                                                    })
                                                })},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localItem.tussenTemp
                                            ),callback:function ($$v) {_vm.$set(_vm.localItem, "tussenTemp", _vm._n($$v))},expression:"\n                                                localItem.tussenTemp\n                                            "}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-nawarm"}},[_vm._v(_vm._s(_vm.$t('prod_xtemp', { ns: 'production', val: _vm.$t('prod_nawarm', { ns: 'production' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('temperature-input',{attrs:{"id":"input-nawarm","editAllowed":_vm.editAllowed,"showFahrenheit":_vm.configUseFahrenheit,"minvalue":_vm.minNawarmTemp,"maxvalue":_vm.maxNawarmTemp,"placeholderC":_vm.$t('prod_xtemperatuur', {
                                                    ns: 'production',
                                                    val: _vm.$t('prod_nawarm', {
                                                        ns: 'production'
                                                    })
                                                })},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localItem.nawarmTemp
                                            ),callback:function ($$v) {_vm.$set(_vm.localItem, "nawarmTemp", _vm._n($$v))},expression:"\n                                                localItem.nawarmTemp\n                                            "}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-button',{attrs:{"disabled":!_vm.editAllowed && !_vm.useWei2a},on:{"click":_vm.calcWaterTemperatuur}},[_vm._v(_vm._s(_vm.$t('prod_xtemp', { ns: 'production', val: _vm.$t('prod_water', { ns: 'production' }) })))])],1),_c('b-col',{attrs:{"sm":"9"}},[_c('temperature-input',{attrs:{"id":"input-itemwatertemperatuur","editAllowed":_vm.editAllowed,"showFahrenheit":_vm.configUseFahrenheit,"minvalue":_vm.minWaterTemp,"maxvalue":_vm.maxWaterTemp,"placeholderC":_vm.$t('prod_xtemperatuur', {
                                                    ns: 'production',
                                                    val: _vm.$t('prod_water', {
                                                        ns: 'production'
                                                    })
                                                })},on:{"input":_vm.set_inputTemperatuur},model:{value:(_vm.waterTemp),callback:function ($$v) {_vm.waterTemp=_vm._n($$v)},expression:"waterTemp"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-itemRWW"}},[_vm._v(_vm._s(_vm.$t('prod_rww', { ns: 'production' }))+"%:")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.strRWWtovMelk,"id":"input-itemRWW","placeholder":_vm.strRWWtovMelk,"disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localItem.restWeiWrongel1Perc
                                            ),callback:function ($$v) {_vm.$set(_vm.localItem, "restWeiWrongel1Perc", $$v)},expression:"\n                                                localItem.restWeiWrongel1Perc\n                                            "}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-percentageWater1"}},[_vm._v(_vm._s(_vm.$t('prod_water', { ns: 'production' }))+"%:")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-percentageWater1","placeholder":_vm.strWatertovRWW,"type":"number","step":"0.1","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localItem.percentageWater1
                                            ),callback:function ($$v) {_vm.$set(_vm.localItem, "percentageWater1", _vm._n($$v))},expression:"\n                                                localItem.percentageWater1\n                                            "}})],1)],1),(_vm.useWei2a)?_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-itemRWW2a"}},[_vm._v(_vm._s(_vm.$t('prod_rww2', { ns: 'production' }))+"%:")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.strRWW2tovRest,"id":"input-itemRWW2a","placeholder":_vm.strRWW2tovRest,"disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localItem.restWeiWrongel2Perc
                                            ),callback:function ($$v) {_vm.$set(_vm.localItem, "restWeiWrongel2Perc", $$v)},expression:"\n                                                localItem.restWeiWrongel2Perc\n                                            "}})],1)],1):_vm._e(),(_vm.useWei2a)?_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-percentageWater2"}},[_vm._v(_vm._s(_vm.$t('prod_waswater2a', { ns: 'production' }))+"%:")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-percentageWater2","title":_vm.strWatertovRWW2,"placeholder":_vm.strWatertovRWW2,"type":"number","step":"0.1","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localItem.percentageWater2
                                            ),callback:function ($$v) {_vm.$set(_vm.localItem, "percentageWater2", _vm._n($$v))},expression:"\n                                                localItem.percentageWater2\n                                            "}})],1)],1):_vm._e()],1)],1)],1)])],1),_c('b-tab',{attrs:{"title":_vm.$t('prod_ingredienten', { ns: 'production' })}},[_c('b-card-text',[(_vm.editAllowed)?_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"lcingredient"}},[_vm._v(" "+_vm._s(_vm.$t('prod_add', { ns: 'production', val: _vm.$t('prod_ingredient', { ns: 'production' }).toLowerCase() }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('div',{staticClass:"form-inline"},[_c('ingredient-selector',{model:{value:(_vm.newIngredientID),callback:function ($$v) {_vm.newIngredientID=$$v},expression:"newIngredientID"}}),_c('b-button',{staticClass:"bgc_aux_normal",attrs:{"disabled":_vm.buttonDisabled},on:{"click":function($event){return _vm.addRecIngredient()}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-down","size":"1x"}})],1)],1)])],1):_vm._e(),_vm._l((_vm.groupedRIList),function(item){return _c('rec-ingredient-card',{key:item.id,attrs:{"ingredientCat":item.categorie,"rilist":item.recingredients,"editAllowed":_vm.editAllowed},on:{"deleteRI":_vm.deleteRecIngredient}})})],2)],1),_c('b-tab',{attrs:{"title":_vm.$t('com_normen', { ns: 'common' })}},[_c('b-card-text',[_c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-vocht"}},[_vm._v(" "+_vm._s(_vm.$t('an_xkaas', { ns: 'analytics', val: _vm.$t('an_vocht', { ns: 'analytics' }).toLowerCase() + '%' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-vocht","placeholder":_vm.$t('an_xkaas', {
                                                    ns: 'analytics',
                                                    val: _vm.$t('an_vochtgehalte', {
                                                        ns: 'analytics'
                                                    }).toLowerCase()
                                                }),"type":"number","step":"0.1","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecNorm.vochtPerc
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecNorm, "vochtPerc", _vm._n($$v))},expression:"\n                                                localRecNorm.vochtPerc\n                                            "}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-vet"}},[_vm._v(_vm._s(_vm.$t('an_xkaas', { ns: 'analytics', val: _vm.$t('an_vetds', { ns: 'analytics' }).toLowerCase() + '%' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-vet","placeholder":_vm.$t('an_xkaas', {
                                                    ns: 'analytics',
                                                    val: _vm.$t('an_vetgehalte', {
                                                        ns: 'analytics'
                                                    }).toLowerCase()
                                                }),"type":"number","step":"0.1","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecNorm.vetDSPerc
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecNorm, "vetDSPerc", _vm._n($$v))},expression:"\n                                                localRecNorm.vetDSPerc\n                                            "}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-zout"}},[_vm._v(_vm._s(_vm.$t('an_xkaas', { ns: 'analytics', val: _vm.$t('an_zoutds', { ns: 'analytics' }).toLowerCase() + '%' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-zout","placeholder":_vm.$t('an_xds', {
                                                    ns: 'analytics',
                                                    val: _vm.$t('an_zoutgehalte', {
                                                        ns: 'analytics'
                                                    }).toLowerCase()
                                                }),"type":"number","step":"0.1","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecNorm.zoutDSPerc
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecNorm, "zoutDSPerc", _vm._n($$v))},expression:"\n                                                localRecNorm.zoutDSPerc\n                                            "}})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-therm"}},[_vm._v(_vm._s(_vm.$t('prod_xtemperatuur', { ns: 'production', val: _vm.$t('prod_therm', { ns: 'production' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('temperature-input',{attrs:{"id":"input-therm","editAllowed":_vm.editAllowed,"showFahrenheit":_vm.configUseFahrenheit,"minvalue":_vm.minThermTemp,"maxvalue":_vm.maxThermTemp,"placeholderC":_vm.$t('com_temperatuur', {
                                                    ns: 'common'
                                                })},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecNorm.thermisatieTemp
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecNorm, "thermisatieTemp", _vm._n($$v))},expression:"\n                                                localRecNorm.thermisatieTemp\n                                            "}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-past"}},[_vm._v(_vm._s(_vm.$t('prod_xtemperatuur', { ns: 'production', val: _vm.$t('prod_past', { ns: 'production' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('temperature-input',{attrs:{"id":"input-past","editAllowed":_vm.editAllowed,"showFahrenheit":_vm.configUseFahrenheit,"minvalue":_vm.minPastTemp,"maxvalue":_vm.maxPastTemp,"placeholderC":_vm.$t('com_temperatuur', {
                                                    ns: 'common'
                                                })},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecNorm.pasteurisatieTemp
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecNorm, "pasteurisatieTemp", _vm._n($$v))},expression:"\n                                                localRecNorm.pasteurisatieTemp\n                                            "}})],1)],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-pekel"}},[_vm._v(" "+_vm._s(_vm.$t('prod_pekelverlies', { ns: 'production' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-pekel","placeholder":_vm.$t('prod_pekelverlies', {
                                                    ns: 'production'
                                                }),"type":"number","step":"0.1","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecNorm.pekelVerlies
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecNorm, "pekelVerlies", _vm._n($$v))},expression:"\n                                                localRecNorm.pekelVerlies\n                                            "}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-itempeni"}},[_vm._v(_vm._s(_vm.$t('prod_peniverlies', { ns: 'production' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-itempeni","placeholder":_vm.$t('prod_peniremark', {
                                                    ns: 'production'
                                                }),"type":"number","step":"0.1","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecNorm.indroogPerc
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecNorm, "indroogPerc", _vm._n($$v))},expression:"\n                                                localRecNorm.indroogPerc\n                                            "}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-pekelDuur"}},[_vm._v(_vm._s(_vm.$t('prod_pekelduur', { ns: 'production' }))+"  ("+_vm._s(_vm.$t('an_uur', { ns: 'analytics' }))+"):")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-pekelDuur","placeholder":_vm.$t('prod_pekelduur', {
                                                    ns: 'production'
                                                }),"type":"number","step":"1","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecNorm.pekelDuur
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecNorm, "pekelDuur", _vm._n($$v))},expression:"\n                                                localRecNorm.pekelDuur\n                                            "}})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-pHvoorpekel"}},[_vm._v(" "+_vm._s(_vm.$t('an_phvp', { ns: 'analytics' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-pHvoorpekel","placeholder":_vm.$t('an_phvp', {
                                                    ns: 'analytics'
                                                }),"type":"number","step":"0.1","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecNorm.pHvoorpekel
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecNorm, "pHvoorpekel", _vm._n($$v))},expression:"\n                                                localRecNorm.pHvoorpekel\n                                            "}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-pHna1dag"}},[_vm._v(" "+_vm._s(_vm.$t('an_ph1dag', { ns: 'analytics' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-pHna1dag","placeholder":_vm.$t('an_ph1dag', {
                                                    ns: 'analytics'
                                                }),"type":"number","step":"0.1","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecNorm.pHna1dag
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecNorm, "pHna1dag", _vm._n($$v))},expression:"\n                                                localRecNorm.pHna1dag\n                                            "}})],1)],1)],1)],1)],1)])],1),_c('b-tab',{attrs:{"title":_vm.$t('com_production', { ns: 'common' })}},[_c('b-card-text',[_c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-progWB"}},[_vm._v(_vm._s(_vm.$t('prod_xprogramma', { ns: 'production', val: _vm.$t('prod_ost', { ns: 'production' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-progWB","placeholder":_vm.$t('prod_xprogramma', {
                                                    ns: 'production',
                                                    val: _vm.$t('prod_ost', {
                                                        ns: 'production'
                                                    })
                                                }),"disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(_vm.localRecProduction.progWB),callback:function ($$v) {_vm.$set(_vm.localRecProduction, "progWB", $$v)},expression:"localRecProduction.progWB"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-progDrain"}},[_vm._v(_vm._s(_vm.$t('prod_xprogramma', { ns: 'production', val: _vm.$t('prod_drainage', { ns: 'production' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-progDrain","placeholder":_vm.$t('prod_xprogramma', {
                                                    ns: 'production',
                                                    val: _vm.$t('prod_drainage', {
                                                        ns: 'production'
                                                    })
                                                }),"disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecProduction.progDrain
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecProduction, "progDrain", $$v)},expression:"\n                                                localRecProduction.progDrain\n                                            "}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-progPers"}},[_vm._v(_vm._s(_vm.$t('prod_xprogramma', { ns: 'production', val: _vm.$t('prod_pers', { ns: 'production' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-progPers","placeholder":_vm.$t('prod_xprogramma', {
                                                    ns: 'production',
                                                    val: _vm.$t('prod_pers', {
                                                        ns: 'production'
                                                    })
                                                }),"disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecProduction.progPers
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecProduction, "progPers", $$v)},expression:"\n                                                localRecProduction.progPers\n                                            "}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-tijdWBProductie"}},[_vm._v(_vm._s(_vm.$t('prod_prodtimeost', { ns: 'production' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-tijdWBProductie","placeholder":_vm.$t('prod_prodtimeost', {
                                                    ns: 'production'
                                                }),"disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecProduction.tijdWBProductie
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecProduction, "tijdWBProductie", $$v)},expression:"\n                                                localRecProduction.tijdWBProductie\n                                            "}})],1)],1)],1)],1)],1)])],1),_c('b-tab',{attrs:{"title":_vm.$t('prod_defaults', { ns: 'production' })}},[_c('b-card-text',[_c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-eiwitMelk"}},[_vm._v(_vm._s(_vm.$t('an_xpercmelk', { ns: 'analytics', val: _vm.$t('an_eiwit', { ns: 'analytics' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-eiwitMelk","placeholder":_vm.$t('an_xpercmelk', {
                                                    ns: 'analytics',
                                                    val: _vm.$t('an_eiwit', {
                                                        ns: 'analytics'
                                                    })
                                                }),"type":"number","step":"0.01","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecComposition.eiwitMelk
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecComposition, "eiwitMelk", _vm._n($$v))},expression:"\n                                                localRecComposition.eiwitMelk\n                                            "}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-vetMelk"}},[_vm._v(_vm._s(_vm.$t('an_xpercmelk', { ns: 'analytics', val: _vm.$t('an_vet', { ns: 'analytics' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-vetMelk","placeholder":_vm.$t('an_xpercmelk', {
                                                    ns: 'analytics',
                                                    val: _vm.$t('an_vet', {
                                                        ns: 'analytics'
                                                    })
                                                }),"type":"number","step":"0.01","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecComposition.vetMelk
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecComposition, "vetMelk", _vm._n($$v))},expression:"\n                                                localRecComposition.vetMelk\n                                            "}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-densMelk"}},[_vm._v(_vm._s(_vm.$t('an_xmelk', { ns: 'analytics', val: _vm.$t('an_density', { ns: 'analytics' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-densMelk","placeholder":_vm.$t('an_xmelk', {
                                                    ns: 'analytics',
                                                    val: _vm.$t('an_density', {
                                                        ns: 'analytics'
                                                    })
                                                }),"type":"number","step":"0.001","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecComposition.densMelk
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecComposition, "densMelk", _vm._n($$v))},expression:"\n                                                localRecComposition.densMelk\n                                            "}})],1)],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-eiwitWei1"}},[_vm._v(_vm._s(_vm.$t('an_xpercwei1', { ns: 'analytics', val: _vm.$t('an_eiwit', { ns: 'analytics' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-eiwitWei1","placeholder":_vm.$t('an_xpercwei1', {
                                                    ns: 'analytics',
                                                    val: _vm.$t('an_eiwit', {
                                                        ns: 'analytics'
                                                    })
                                                }),"type":"number","step":"0.01","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecComposition.eiwitWei1
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecComposition, "eiwitWei1", _vm._n($$v))},expression:"\n                                                localRecComposition.eiwitWei1\n                                            "}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-vetWei1"}},[_vm._v(_vm._s(_vm.$t('an_xpercwei1', { ns: 'analytics', val: _vm.$t('an_vet', { ns: 'analytics' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-vetWei1","placeholder":_vm.$t('an_xpercwei1', {
                                                    ns: 'analytics',
                                                    val: _vm.$t('an_vet', {
                                                        ns: 'analytics'
                                                    })
                                                }),"type":"number","step":"0.01","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecComposition.vetWei1
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecComposition, "vetWei1", _vm._n($$v))},expression:"\n                                                localRecComposition.vetWei1\n                                            "}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-densWei1"}},[_vm._v(_vm._s(_vm.$t('an_xwei1', { ns: 'analytics', val: _vm.$t('an_density', { ns: 'analytics' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-densWei1","placeholder":_vm.$t('an_xwei1', {
                                                    ns: 'analytics',
                                                    val: _vm.$t('an_density', {
                                                        ns: 'analytics'
                                                    })
                                                }),"type":"number","step":"0.001","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecComposition.densWei1
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecComposition, "densWei1", _vm._n($$v))},expression:"\n                                                localRecComposition.densWei1\n                                            "}})],1)],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-eiwitWei2"}},[_vm._v(_vm._s(_vm.$t('an_xpercwei2', { ns: 'analytics', val: _vm.$t('an_eiwit', { ns: 'analytics' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-eiwitWei2","placeholder":_vm.$t('an_xpercwei2', {
                                                    ns: 'analytics',
                                                    val: _vm.$t('an_eiwit', {
                                                        ns: 'analytics'
                                                    })
                                                }),"type":"number","step":"0.01","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecComposition.eiwitWei2
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecComposition, "eiwitWei2", _vm._n($$v))},expression:"\n                                                localRecComposition.eiwitWei2\n                                            "}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-vetWei2"}},[_vm._v(_vm._s(_vm.$t('an_xpercwei2', { ns: 'analytics', val: _vm.$t('an_vet', { ns: 'analytics' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-vetWei2","placeholder":_vm.$t('an_xpercwei2', {
                                                    ns: 'analytics',
                                                    val: _vm.$t('an_vet', {
                                                        ns: 'analytics'
                                                    })
                                                }),"type":"number","step":"0.01","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecComposition.vetWei2
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecComposition, "vetWei2", _vm._n($$v))},expression:"\n                                                localRecComposition.vetWei2\n                                            "}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-densWei2"}},[_vm._v(" "+_vm._s(_vm.$t('an_xwei2', { ns: 'analytics', val: _vm.$t('an_density', { ns: 'analytics' }) }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-densWei2","placeholder":_vm.$t('an_xwei2', {
                                                    ns: 'analytics',
                                                    val: _vm.$t('an_density', {
                                                        ns: 'analytics'
                                                    })
                                                }),"type":"number","step":"0.001","disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(
                                                _vm.localRecComposition.densWei2
                                            ),callback:function ($$v) {_vm.$set(_vm.localRecComposition, "densWei2", _vm._n($$v))},expression:"\n                                                localRecComposition.densWei2\n                                            "}})],1)],1)],1)],1)],1)])],1),(_vm.closedRecipes != undefined)?_c('b-tab',{attrs:{"title":_vm.$t('com_historie', { ns: 'common' })}},[_c('b-card-text',[_c('b-table',{attrs:{"striped":"","responsive":"","fields":_vm.fields,"items":_vm.closedRecipes,"primary-key":""}})],1)],1):_vm._e(),(_vm.activiteitenLijst != undefined)?_c('b-tab',{attrs:{"title":_vm.$t('com_activitylog', { ns: 'common' })}},[_c('b-card-text',[_c('b-table',{attrs:{"striped":"","responsive":"","fields":_vm.fieldsActiviteiten,"items":_vm.activiteitenLijst,"primary-key":""}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }